"use client";
import React, { useEffect, useState } from "react";

import { Heart, MessageSquare, Send, Share, Trash } from "lucide-react";
import { fetcher } from "../../lib/utils";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/navigation";

const variations = {
  like: { text: "Like", icon: Heart },
  comment: { text: "Reply", icon: MessageSquare },
  share: { text: "Share", icon: Send },
  delete: { text: "Delete", icon: Trash },
};

const PostInteraction = (props) => {
  const router = useRouter();
  let { post, variant, isLiked: initialSet } = props;
  const [isLiked, setIsLiked] = React.useState(initialSet);
  const [fetchLiked, setFetchLiked] = useState(false);
  const { data, error, isLoading } = useSWRImmutable(fetchLiked ? "/api/post/" + (isLiked ? "un" : "") + variant + "?postId=" + post.id : null, fetcher);

  const handleClick = () => {
    if (variant == "like") {
      setFetchLiked(true);
    } else if (variant == "delete") {
      console.log("DELETE");
    } else if (variant == "comment") {
      console.log("comment");
      // router.push("/post/" + post.id + "/comments");
      if (props.replyClicked) {
        props.replyClicked();
      }
    }
  };

  useEffect(() => {
    if ((isLoading || data?.liked) && fetchLiked) {
      setIsLiked((i) => !i);
    }

    if (data?.liked && fetchLiked) {
      setIsLiked(true);
    } else if (data?.unliked && fetchLiked) {
      setIsLiked(false);
    }
    setFetchLiked(false);
  }, [data, isLoading, error]);

  const handleDelete = async (action) => {
    if (action == "cancel") {
      console.log("CANCEL");
    } else if (action == "continue") {
      console.log("delete");
      let deleted = await props.deletePost();
      if (deleted == true) {
        console.log("deleted", deleted);
      }
    }
  };

  const Comp = variations[variant]["icon"];

  const component = (includeLink) => {
    let link = null;
    if (includeLink) {
      link = handleClick;
    }
    return (
      <button onClick={handleClick} className="flex flex-row items-center space-x-1 text-slate-500">
        <Comp size={14} fill={isLiked ? "slate" : "white"} color={isLiked ? "text-slate-5" : "gray"} />
        <span className="ml-2 text-xs">{variations[variant]["text"]}</span>
      </button>
    );
  };

  if (variant == "delete") {
    return (
      <AlertDialog>
        <AlertDialogTrigger>{component()}</AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>This action cannot be undone. This will permanently delete your post.</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => handleDelete("cancel")}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => handleDelete("continue")}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
  return component();
};

export default PostInteraction;
