import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";
import Link from "next/link";

const buttonVariants = cva("space-x-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      default: "bg-primary text-primary-foreground hover:bg-primary/90",
      destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      full_card: "w-full text-primary bg-secondary text-secondary-foreground  rounded-lg border bg-card text-card-foreground p-4 relative rounded-lg shadow-lg bg-white dark:bg-zinc-950 overflow-hidden ",
    },
    size: {
      default: "h-8 px-4 py-2",
      sm: "h-9 rounded-md px-3",
      lg: "h-11 rounded-md px-8",
      icon: "h-10 w-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

const NavLink = React.forwardRef(({ className, href, variant, size, asChild = false, ...props }, ref) => {
  return <Link href={href} className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
NavLink.displayName = "NavLink";

export { NavLink, buttonVariants };
