"use client";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import dynamic from "next/dynamic";
import React, { forwardRef, useEffect, useState } from "react";

import { cn, trimUrl } from "@/lib/utils";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Star, Heart } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import Moment from "react-moment";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { handleOnDrag } from "../Collections/display_user_collection";
import DisplayThing from "../Thing/DisplayThing";
import { PostCard, ActionPostCard, PostCardContent, PostCardDescription, PostCardFooter, PostCardHeader, PostCardTitle } from "../ui/card_post";
import { useToast } from "../ui/use-toast";
import PostInteraction from "./post_interaction";
import PostReplies from "./post_replies";
import { LogoSVG } from "../Sidebar";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import DisplayCollection from "../Collections/display_collection";
import CollectionInteraction from "../Collections/collection_interaction";
import Compose from "../Compose/compose";
import { Card, CardContent } from "../ui/card";
const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });

const PostThings = (props) => {
  let { post } = props;

  let { thingsConnection = { edges: [] } } = post;
  let things = thingsConnection.edges?.map((e) => {
    return { ...e.node, logDate: e.logDate };
  });
  return (
    <div className="flex flex-row flex-wrap mt-4">
      {/* {JSON.stringify(post)} */}
      {/* <div className="w-[600px]">{JSON.stringify(things)}</div> */}
      {things &&
        things.map((thing, i) => {
          return <DisplayThing thing={thing} key={i} variant="home" includeName />;
        })}
    </div>
  );
};

const PostRating = (props) => {
  let { post } = props;

  let { rating = {} } = post;
  if (rating.length == 0 || !rating[0]?.value) return null;
  let val = parseInt(rating[0].value);
  const filledStars = Array(val).fill(<Star color="black" size={16} />);
  const emptyStars = Array(5 - val).fill(<Star color="#DDD" size={16} />);

  return (
    <div className="flex flex-row space-x-0">
      {filledStars.map((star, index) => (
        <div key={`filled-${index}`}>{star}</div>
      ))}
      {emptyStars.map((star, index) => (
        <div key={`empty-${index}`}>{star}</div>
      ))}
    </div>
  );
};

const PostImages = (props) => {
  const [openLightbox, setOpenLightbox] = React.useState(false);
  let { post } = props;
  let { images } = post;

  if (!images || images.length == 0) {
    return null;
  }

  return (
    <div className="flex flex-row" style={{ marginTop: 20, marginHorizontal: -20 }}>
      {images.map((image, i) => {
        return (
          <button onDragStart={(e) => handleOnDrag(e, image)} onClick={() => setOpenLightbox(true)} key={i}>
            <img src={image.url} style={{ width: 200, height: 200 }} />
          </button>
        );
      })}
      <Lightbox
        open={openLightbox}
        className="z-10000000"
        style={{ zIndex: 10000000 }}
        close={() => setOpenLightbox(false)}
        slides={images.map((i) => {
          return { src: i.url };
        })}
      />
    </div>
  );
};

const DisplayPostCollection = (props) => {
  let { elements = [], collection = {}, firstElement } = props;
  let count = !!firstElement ? 4 : 5;
  if (elements.length == 0) return null;
  return (
    <Link href={`/${collection.owner.username}/${collection.slug}`} className="w-[300px] min-w[300px] max-w-[300px]">
      <ul className="flex flex-row overflow-hidden rounded-md h-[112px]">
        {firstElement && (
          <li
            className="h-full rounded-md overflow-hidden border border-slate-600 w-[80px] min-w-[80px]"
            style={{
              boxShadow: "2px 0 7px #000",
              marginRight: -25,
              zIndex: elements.length - i,
            }}
          >
            <img src={firstElement.image} className="w-full object-cover h-full" />
          </li>
        )}
        {elements.map((thing, i) => {
          if (thing.__typename != "Thing") return null;
          if (firstElement && firstElement.id == thing.id) return null;
          count -= 1;
          if (count == 0) return null;
          return (
            <li
              className="h-full rounded-md overflow-hidden border border-slate-600 w-[80px] min-w-[80px]"
              style={{
                boxShadow: "2px 0 7px #000",
                marginRight: -25,
                zIndex: elements.length - i,
              }}
            >
              <img src={thing.image} className="w-full object-cover h-full" />
            </li>
          );
        })}
      </ul>
      <div className="mt-2">
        <div className="font-bold text-xl flex gap-2">
          {collection.playlistName || collection.name}
          <div className="flex items-center gap-1">
            <div className="text-sm text-slate-500">{collection.element_count}</div>
            <div className="flex items-center space-x-1">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="text-sm text-slate-500">
                    <LogoSVG className="w-5 h-5" />
                  </TooltipTrigger>
                  <TooltipContent>
                    {collection.element_labels?.map((l) => {
                      let label = l.label;
                      if (l.label == "Playlist") {
                        label = "collection";
                      }
                      return (
                        <div>
                          {l.totalElementCount} <span className="lowercase">{l.totalElementCount == 1 ? l.label : l.label + "s"}</span>
                        </div>
                      );
                    })}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-1">
          <div className="flex space-x-2">
            <img src={collection?.owner?.profile_picture} className="rounded-full w-5 h-5" />
            <div className="flex items-center space-x-1">
              <div className="text-sm">{collection?.owner?.name}</div>
              <div className="text-xs text-slate-500">@{collection?.owner?.username}</div>
            </div>
          </div>
          <div className="flex gap-3">
            {/* {!!collection.like_count && (
              <div className="text-sm text-slate-500 flex gap-1 items-center">
                {collection.like_count}
                <Heart className="w-4 h-4 text-slate-500" />
              </div>
            )} */}
            <div className="flex gap-3">
              <CollectionInteraction location="collection_view" initialLikeCount={collection.like_count} collection={collection} />
            </div>
          </div>
        </div>
      </div>
      {/* <Separator className="mt-2" /> */}
    </Link>
  );
};

const postVariants = cva("justify-center card-bg whitespace-wrap rounded-md text-sm font-medium w-full", {
  variants: {
    variant: {
      default: "card text-primary-foreground",
      ghost: "hover:bg-accent hover:text-accent-foreground",
    },
    size: {
      default: "mh-10 py-2",
      sm: "h-9 rounded-md px-3",
      lg: "h-11 rounded-md px-8",
      icon: "h-10 w-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

function convertURLsToLinks(text) {
  if (!text) return null;
  const urlRegex = /(\b(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?)/g;
  return {
    __html: text.replace(urlRegex, function (url) {
      const protocol = url.startsWith("http") ? "" : "http://";
      let newUrl = trimUrl(url);
      return `<a href="https://${newUrl}" ondragstart="event.stopPropagation()" draggable target="_blank" rel="noopener noreferrer" style="word-wrap: break-all">${url}</a>`;
    }),
  };
}

const Post = forwardRef(({ className, variant, size, asChild = false, post, ...props }, ref) => {
  const Comp = asChild ? Slot : "div";
  const [session, setSession] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [composeReply, setComposeReply] = useState(false);
  const supabase = createClientComponentClient();
  const { toast } = useToast();
  let currentUser = session?.user;
  // console.log("SESSSION", session);
  // let currentUser = { id: "YSCbEHFxm0gnXOPEKmRb8WUVO7j2", profile_picture: "https://fastly.picsum.photos/id/818/200/300.jpg?hmac=lE_Le7TxnELgojCX97DVlE9CLlZJWqnfbaQR3Chjstw", username: "danfein", name: "dan" };
  // let user = currentUser;
  useEffect(() => {
    const getUser = async () => {
      const {
        data: { session: tempSession },
        error,
      } = await supabase.auth.getSession();
      setSession(tempSession);
    };
    getUser();
  }, []);
  if (!post) return null;
  let isAuthor = post.author && post.author?.id == currentUser?.id;
  let postType = post.__typename;

  const deletePost = async () => {
    if (isAuthor) {
      try {
        console.log("DELETING", post.id, "POST");
        const response = await fetch("/api/post/delete", {
          method: "DELETE",
          body: JSON.stringify({ postId: post.id, images: post.images }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Success:", JSON.stringify(responseData));

        // Here, you can also handle your toast notification
        toast({
          title: `Post deleted!`,
        });
        setIsDeleted(true);
        return true; // Or return responseData if you want to return the response from the server
      } catch (error) {
        console.error("Error:", error);
        return false; // Or return the error if you need to handle it later
      }
    }
  };

  useEffect(() => {
    const handleAnchorDragStart = (event) => {
      console.log("Anchor drag started", event);
      event.stopPropagation(); // Prevents the div's drag event from firing
    };

    // Query for anchor tags specifically
    const anchors = document.querySelectorAll(".body_text a");
    anchors.forEach((anchor) => {
      anchor.setAttribute("draggable", true);
      anchor.addEventListener("dragstart", handleAnchorDragStart);
    });

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("dragstart", handleAnchorDragStart);
      });
    };
  }, []);

  const UserInfo = ({ user, date, variant, BodyComponent, rooms }) => {
    if (variant == "action") {
      return (
        <Link href={`/user/${user?.username}`} className="flex flex-row space-x-4" draggable onDragStart={(e) => handleOnDrag(e, post.author)}>
          <Image unoptimized className="w-6 h-6 rounded-full" width={6} height={6} src={user?.profile_picture} alt="Profile picture" />
          <div className="flex flex-col justify-around">
            <div className="flex flex-row gap-2">
              <PostCardTitle className="flex flex-row">
                <span className="break-normal">{user?.name}</span>
                <BodyComponent />
              </PostCardTitle>
            </div>
            <PostCardDescription>
              <Moment className="text-xs" fromNow>
                {date}
              </Moment>
              {rooms?.length > 0 && (
                <span>
                  Posted to <Link href={`/a/${rooms[0].slug}`}>{rooms[0].name}</Link>
                </span>
              )}
            </PostCardDescription>
          </div>
        </Link>
      );
    }

    return (
      <Link href={`/user/${user?.username}`} className="flex flex-row space-x-4" draggable onDragStart={(e) => handleOnDrag(e, post.author)}>
        <Image unoptimized className="w-10 h-10 rounded-full" width={10} height={10} src={user?.profile_picture} alt="Profile picture" />
        <div className="flex flex-col justify-around gap-0">
          <div className="flex flex-row gap-2">
            <PostCardTitle>{user?.display_name}</PostCardTitle>
            <PostCardDescription>@{user?.username}</PostCardDescription>
          </div>
          <PostCardDescription className="flex flex-col gap-0">
            <Moment className="text-xs" fromNow>
              {date}
            </Moment>
            {rooms?.length > 0 && (
              <div>
                Posted to{" "}
                <Link href={`/a/${rooms[0].slug}`} className="text-black">
                  {rooms[0].name}
                </Link>
              </div>
            )}
            {post.is_reply && (
              <span>
                Replying to post by <Link href={`/post/${post.reply_to?.id}`}>{post.reply_to?.author?.name}</Link>
              </span>
            )}
          </PostCardDescription>
        </div>
      </Link>
    );
  };

  if (isDeleted) {
    return null;
  }
  let body = convertURLsToLinks(post.body);

  if (variant == "bento") {
    return (
      <Comp className={cn(postVariants({ variant, size, className }))} ref={ref} {...props} onDragStart={(e) => handleOnDrag(e, post)} draggable>
        <div className="flex flex-col space-y-4">
          <PostCardHeader>
            <UserInfo user={post.author} date={post.date} rooms={post.rooms} />
          </PostCardHeader>

          <PostCardContent className="">
            <p className="body_text text-base flex-wrap break-words" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={body} />
            {/* {JSON.stringify(post)} */}
            {/* <ReactQuill value={post.ody} readOnly={true} theme="bubble" /> */}
            {post.images?.length > 0 && <PostImages {...props} post={post} />}
            <PostThings {...props} post={post} />
            <PostRating {...props} post={post} />
            {post.is_reply && (
              <CardContent>
                <Post post={post.reply_to} variant="inline_reply" />
              </CardContent>
            )}
          </PostCardContent>
          <PostCardFooter>
            <div className="flex flex-row w-full justify-between">
              <div className="flex flow-row items-center space-x-4">
                <PostInteraction variant="like" post={post} isLiked={post.isLiked} />
                {/* <PostInteraction variant="comment" post={post} /> */}
                {/* <PostInteraction variant="share" post={post} /> */}
              </div>
            </div>
          </PostCardFooter>
          {/* <PostCardFooter>
            <PostReplies post={post} />
          </PostCardFooter> */}
        </div>
      </Comp>
    );
  } else if (variant == "inline_reply") {
    return (
      <Comp className={cn(postVariants({ variant, size, className }))} ref={ref} {...props} onDragStart={(e) => handleOnDrag(e, post)} draggable>
        <div className="flex flex-col space-y-4">
          <PostCardHeader>
            <UserInfo user={post.author} date={post.date} rooms={post.rooms} />
          </PostCardHeader>

          <PostCardContent className="">
            <p className="body_text text-base flex-wrap break-words" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={body} />
            {/* {JSON.stringify(post)} */}
            {/* <ReactQuill value={post.ody} readOnly={true} theme="bubble" /> */}
            {post.images?.length > 0 && <PostImages {...props} post={post} />}
            {post.things?.length > 0 && <PostThings {...props} post={post} />}
            <PostRating {...props} post={post} />
          </PostCardContent>
        </div>
      </Comp>
    );
  }
  const replyClicked = () => {
    setComposeReply(!composeReply);
  };

  if (postType == "Post") {
    return (
      <Comp className={cn(postVariants({ variant, size, className }))} ref={ref} {...props} onDragStart={(e) => handleOnDrag(e, post)} draggable>
        <div className="flex flex-col space-y-4">
          <PostCard>
            <PostCardHeader>
              <UserInfo user={post.author} date={post.date} rooms={post.rooms} />
            </PostCardHeader>
            <PostCardContent className="pl-14">
              <PostRating {...props} post={post} />

              <p className="body_text text-base" dangerouslySetInnerHTML={body} />
              {/* {JSON.stringify(post)} */}
              {/* <ReactQuill value={post.ody} readOnly={true} theme="bubble" /> */}

              {post.images?.length > 0 && <PostImages {...props} post={post} />}
              {post.thingsConnection?.edges?.length > 0 && <PostThings {...props} post={post} />}
              {post.is_reply && (
                <Card>
                  <Post post={post.reply_to} variant="inline_reply" />
                </Card>
              )}
              <PostCardFooter>
                <div className="flex flex-row w-full justify-between">
                  <div className="flex flow-row items-center space-x-4">
                    <PostInteraction variant="like" post={post} isLiked={post.isLiked} />
                    {/* <PostInteraction variant="comment" post={post} replyClicked={replyClicked} />
                    <PostInteraction variant="share" post={post} /> */}
                  </div>
                  <div className="flex flow-row items-center space-x-4">{isAuthor && <PostInteraction variant="delete" post={post} deletePost={deletePost} />}</div>
                </div>
              </PostCardFooter>
            </PostCardContent>

            {composeReply && (
              <PostCardFooter>
                <Compose isInlineReply post={post} />
              </PostCardFooter>
            )}
            {post.replies?.length > 0 && (
              <PostCardFooter>
                <PostReplies post={post} />
              </PostCardFooter>
            )}
          </PostCard>
        </div>
      </Comp>
    );
  } else if (post.actions?.length > 0 || postType == "Action") {
    let target = post.actions.length > 0 ? post.actions[0].target : post.target;
    let origActions = post.actions || [];
    const actions = origActions.filter((action, index, self) => index === self.findIndex((t) => t.target.id === action.target.id));
    let base_post = actions.length > 0 ? actions[0] : post;
    let second_action = actions.length > 1 ? actions[1] : {};
    let user = actions.length > 0 ? actions[0].source_user : source_user;
    const RenderMultipleActions = () => {
      return (
        <span>
          {" "}
          <a href={`/t${second_action.target?.type?.slug}/${second_action.target.slug}`}>
            {actions?.length == 2 ? (
              second_action.target?.name
            ) : (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <span> {actions.length - 1} others</span>
                  </TooltipTrigger>
                  <TooltipContent>
                    {actions.map((action, i) => {
                      // if (i === 0) return;
                      return <a href={`/${action.target.owner.username}/${action.target.slug}`}>{action.target.playlistName || action.target.name}</a>;
                    })}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </a>
        </span>
      );
    };

    if (post.action == "LIKED_POST") return null;
    return (
      <Comp className={cn(postVariants({ variant, size, className }))} ref={ref} {...props} onDragStart={(e) => handleOnDrag(e, post)} draggable>
        <div className="flex flex-col space-y-0">
          <PostCard>
            {/* {JSON.stringify(actions)} */}
            <div className="flex flex-row items-center space-x-1">
              {/* <div class="max-w-sm"> {JSON.stringify(post)}</div>
              aaaa {JSON.stringify(actions)} */}
              <PostCardHeader className="flex flex-row items-center space-x-4">
                <Link href={`/user/${user?.username}`} draggable onDragStart={(e) => handleOnDrag(e, user)}>
                  <Image unoptimized className="w-8 h-8 min-h-[36px] min-w-[36px] rounded-full" width={12} height={12} src={user.profile_picture} alt="Profile picture" />
                </Link>
                <div className="flex flex-col justify-around">
                  <span className="text-base">
                    <span className="break-normal pr-1">{user?.name}</span>
                    <span>
                      {["ADDED_PLAYLIST_THING"].includes(post.action) && (
                        <span className="font-normal">
                          {post.action == "ADDED_PLAYLIST_THING" && <span>added </span>}
                          {actions?.length > 0 ? (
                            <span>
                              <a href={`/t/${base_post.object?.type?.slug}/${base_post?.object?.slug}`} className="font-bold">
                                {base_post.object?.name}
                              </a>
                              {actions.length >= 2 ? <RenderMultipleActions action={second_action} actions={actions} /> : null}
                            </span>
                          ) : (
                            <a href={`/t/${target?.type?.slug}/${target.slug}`}>{target?.name}</a>
                          )}
                          <span className="font-normal">{post.action == "ADDED_PLAYLIST_THING" ? " to collection " : post.action}</span>
                          <span className="font-bold">{target?.playlistName || target.name}</span>
                        </span>
                      )}
                      {["ADDED_PLAYLIST_THING", "CREATED_PLAYLIST", "LIKED_COLLECTION"].includes(post.action) && (
                        <span className="font-normal">
                          {post.action == "LIKED_COLLECTION" && <span>liked </span>}
                          {post.action == "CREATED_PLAYLIST" && <span>created </span>}
                          {["ADDED_PLAYLIST_THING", "CREATED_PLAYLIST", "LIKED_COLLECTION"].includes(post.action) && <span className="font-bold">{target?.playlistName || target.name}</span>}
                          {actions?.length > 0 ? (
                            <span>
                              <a href={`/t/${base_post.object?.type?.slug}/${base_post?.object?.slug}`} className="font-bold">
                                {base_post.object?.name}b
                              </a>
                              {actions.length >= 2 ? <RenderMultipleActions action={second_action} actions={actions} /> : null}
                            </span>
                          ) : (
                            <a href={`/t/${target?.type?.slug}/${target.slug}`}>{target?.name}</a>
                          )}
                        </span>
                      )}
                      {post.action == "FOLLOWED_THING" && (
                        <span className="font-normal">
                          <span>followed </span>
                          {actions?.length >= 2 ? (
                            <span>
                              <a href={`/t/${actions[0].target?.type?.slug}/${actions[0].target.slug}`}>{actions[0].target?.name}</a>
                              {actions.length >= 2 ? <RenderMultipleActions action={second_action} actions={actions} /> : null}
                            </span>
                          ) : (
                            <a href={`/t/${target?.type?.slug}/${target.slug}`}>{target?.name}</a>
                          )}
                          {/* <span className="font-bold">{target?.playlistName || target.name}</span> */}
                        </span>
                      )}
                      {post.action == "FOLLOWED_USER" && (
                        <span className="font-normal">
                          <span>followed </span>
                          {actions?.length >= 2 ? (
                            <span>
                              <a href={`/${actions[0].target?.username}`}>{actions[0].target?.name}</a>
                              {actions.length >= 2 ? <RenderMultipleActions action={second_action} actions={actions} /> : null}
                            </span>
                          ) : (
                            <a href={`/${target?.username}`}>{target?.name}</a>
                          )}
                          {/* <span className="font-bold">{target?.playlistName || target.name}</span> */}
                        </span>
                      )}
                    </span>
                  </span>
                  <PostCardDescription>
                    <Moment fromNow>{base_post.date}</Moment>
                  </PostCardDescription>
                </div>
              </PostCardHeader>
            </div>

            {["ADDED_PLAYLIST_THING", "LIKED_COLLECTION", "CREATED_PLAYLIST"].includes(post.action) && (
              <div className="flex flex-row">
                {/* {JSON.stringify(target)} */}
                {/* <DisplayCollection collection={base_post.target} elements={target.elements} variant="post" /> */}
                <DisplayPostCollection firstElement={base_post.object} collection={base_post.target} elements={target.elements} />
              </div>
            )}
            {post.action == "FOLLOWED_THING" && actions.length > 1 && (
              <div className="flex flex-row">
                {/* {JSON.stringify(actions)} */}
                {actions.length > 1 &&
                  actions?.map((a, i) => {
                    console.log("HELLO", a.target);
                    return <DisplayThing thing={a.target} variant="home" />;
                  })}
                {/* {actions.length == 1 && <DisplayThing thing={target} variant="home" />} */}
              </div>
            )}
            {post.action !== "ADDED_PLAYLIST_THING" && post.action != "FOLLOWED_THING" && post.action != "FOLLOWED_USER" && post.action != "LIKED_COLLECTION" && post.action}
          </PostCard>
        </div>
      </Comp>
    );
  }
});
Post.displayName = "Post";

export { Post, postVariants };
