import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { categoriesList } from "../categories";
import { getTypeFromThing } from "../utils";

export const usePlaylistsStore = create(
  persist(
    (set, get) => ({
      userPlaylists: [],
      stagePlaylists: [],
      likedPlaylists: [],
      resetPlaylists: () => {
        return set({
          userPlaylists: [],
          stagePlaylists: [],
          likedPlaylists: [],
        });
      },
      setUserPlaylists: (newPlaylists) => {
        return set({
          userPlaylists: newPlaylists.map((playlist) => ({
            ...playlist,
            elements: playlist?.elementsConnection?.edges?.map((edge) => ({
              id: edge.node.id,
              name: edge.node.name,
              current_stage: edge.node.current_stage,
              image: edge.node.image,
              slug: edge.node.slug,
              date_added: edge.date_added,
              __typename: edge.node.__typename,
            })),
            elementsConnection: undefined, // This removes elementsConnection from the final object
          })),
        });
      },
      setLikedPlaylists: (newPlaylists) => {
        return set({
          likedPlaylists: newPlaylists.map((playlist) => ({
            ...playlist,
            elements: playlist?.elementsConnection?.edges?.map((edge) => ({
              id: edge.node.id,
              name: edge.node.name,
              current_stage: edge.node.current_stage,
              image: edge.node.image,
              slug: edge.node.slug,
              date_added: edge.date_added,
              __typename: edge.node.__typename,
            })),
            elementsConnection: undefined, // This removes elementsConnection from the final object
          })),
        });
      },
      setStagePlaylists: (newPlaylists) => {
        return set({
          stagePlaylists: newPlaylists.map((playlist) => ({
            ...playlist,
          })),
        });
      },
      getUserPlaylists: () => get().userPlaylists,
      getStagePlaylists: () => get().stagePlaylists,
      updatePlaylist: (updatedPlaylist) => {
        let { userPlaylists } = get();
        let updatedPlaylists = userPlaylists.map((playlist) => {
          if (playlist.id == updatedPlaylist.id) {
            return updatedPlaylist;
          }
          return playlist;
        });
        set({ userPlaylists: updatedPlaylists });
      },
      updateMultipleStages: (things) =>
        set((state) => {
          let { stagePlaylists } = state;

          things.forEach((thing) => {
            const type = getTypeFromThing(thing);
            const newStage = thing.current_stage;

            if (type in categoriesList) {
              let typeThingId = categoriesList[type]?.["thing"]?.["id"];
              let stagePlaylist = stagePlaylists.find(
                (playlist) => playlist.thing.id === typeThingId
              );

              if (stagePlaylist) {
                let existingThingIndex = stagePlaylist.things.findIndex(
                  (t) => t.id === thing.id
                );

                if (existingThingIndex !== -1) {
                  stagePlaylist.things[existingThingIndex] = {
                    ...stagePlaylist.things[existingThingIndex],
                    current_stage: newStage,
                  };
                } else {
                  stagePlaylist.things.push({
                    ...thing,
                    current_stage: newStage,
                  });
                }
              }
            }
          });

          return {
            stagePlaylists: stagePlaylists.map((playlist) => {
              if (
                things.some(
                  (thing) =>
                    getTypeFromThing(thing) in categoriesList &&
                    categoriesList[getTypeFromThing(thing)]?.["thing"]?.[
                      "id"
                    ] === playlist.thing.id
                )
              ) {
                return stagePlaylists.find(
                  (pl) => pl.thing.id === playlist.thing.id
                );
              }
              return playlist;
            }),
          };
        }),
      updateStagePlaylistThingStage: ({ type, thing, newStage }) =>
        set((state) => {
          let { stagePlaylists } = state;
          console.log("HEREEEEEE123", type, thing, newStage);
          if (!thing) {
            console.error("Need a thing to update!");
          }
          if (type in categoriesList) {
            let typeThingId = categoriesList[type]?.["thing"]?.["id"];
            let stagePlaylist = stagePlaylists.find(
              (playlist) => playlist.thing.id === typeThingId
            );
            if (stagePlaylist) {
              let existingThingIndex = stagePlaylist.things.findIndex(
                (t) => t.id === thing.id
              );
              if (existingThingIndex !== -1) {
                stagePlaylist.things[existingThingIndex] = {
                  ...stagePlaylist.things[existingThingIndex],
                  current_stage: newStage,
                };
              } else {
                stagePlaylist.things.push({
                  ...thing,
                  current_stage: newStage,
                });
              }
            } else {
              stagePlaylist = {
                ...thing["type"],
                thing: { ...thing["type"] },
                element_count: 1,
                things: [
                  {
                    ...thing,
                    current_stage: newStage,
                  },
                ],
              };
              stagePlaylists.push(stagePlaylist);
            }
            console.log("HEREEEEEE");
            return {
              stagePlaylists: stagePlaylists.map((playlist) => {
                return playlist.thing?.id === typeThingId
                  ? stagePlaylist
                  : playlist;
              }),
            };
          }
          return {};
        }),
      addElementToPlaylist: (playlistId, element) =>
        set((state) => {
          const updatedPlaylists = state.userPlaylists.map((playlist) => {
            if (playlist.id === playlistId) {
              const elementExists = playlist.elements?.find(
                (e) => e.id === element.id
              );
              if (!elementExists) {
                return {
                  ...playlist,
                  element_count: playlist.element_count + 1,
                  elements: Array.isArray(playlist.elements)
                    ? [...playlist.elements, element]
                    : [element],
                };
              }
            }

            return playlist;
          });
          return { userPlaylists: updatedPlaylists };
        }),
      incrementLinkCount: (playlistId) =>
        set((state) => {
          const updatedPlaylists = state.userPlaylists.map((playlist) => {
            console.log("SEARCHING FOR", playlistId);
            if (playlist.id === playlistId) {
              console.log("UPDATING", playlistId);
              return { ...playlist, link_count: playlist.link_count + 1 };
            }
            return playlist;
          });
          return { userPlaylists: updatedPlaylists };
        }),
    }),
    {
      name: "playlists", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
