import React from "react";
import { Button } from "../../ui/button";

const BentoHeader = (props) => {
  let { collection } = props;
  let { thing_count, link_count, image_count } = collection;
  return (
    <div className="bg-red-100 h-full">
      {" "}
      <header className="relative p-6 bg-zinc-900 rounded-lg h-full overflow-hidden">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-4">
            <h1 className="text-3xl font-bold text-white">{collection.name}</h1>
            {!!collection.description && <p className="text-md text-white">{collection.description}</p>}
            <p className="text-md text-white">Created by {collection.owner?.name}</p>
            <p className="text-md text-white">
              {" "}
              {collection.follower_count} follower{collection.follower_count == 1 ? "" : "s"}
            </p>
            <Button className="w-auto self-start " variant="outline">
              Follow
            </Button>
          </div>

          {/* <div className="image-row flex justify-end h-full">
            <div className="image-row-cover">
              {collection.thingsConnection?.edges.slice(0, 6).map((e, i) => (
                <img src={e.node?.image} alt={`Collection Image ${i}`} key={i} />
              ))}
            </div>
            <div className="image-row-cover"></div>
          </div> */}
        </div>
      </header>
    </div>
  );
};

export default BentoHeader;
