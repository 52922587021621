"use client";
import { DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from "@/components/ui/dropdown-menu";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { Check, UserPlus } from "lucide-react";
import { initiateUpdateLink } from "../Collections/display_user_collection";
import { useToast } from "../ui/use-toast";

export const addToCollection = (collection, asset, ...props) => {
  let { thing = {}, link = {}, toast } = props;
  const addElementToPlaylist = usePlaylistsStore.getState().addElementToPlaylist;

  let data = { playlistId: collection.id, type: asset.__typename };
  const thingId = thing?.id;
  const linkId = link?.id;
  data["assetId"] = asset?.id || thingId || linkId;
  fetch("/api/library/collections", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      console.log("Success adding link:", JSON.stringify(response));
      addElementToPlaylist(collection.id, asset);
      console.log("ADDING ELEMENT TO PLAYLIST");
      let { totalCount: element_count = 0 } = collection;
      initiateUpdateLink({}, asset.url, asset.id);
      if (!toast) return;
      if (element_count == response.total_count) {
        toast({
          title: `${asset.__typename == "Link" ? asset.url : asset.name} already in ${collection.name}`,
          style: { color: "red" },
        });
      } else {
        // incrementLinkCount(collection.id);
        toast({
          title: `Saved ${asset.__typename == "Link" ? asset.url : asset.name} to ${collection.name}`,
        });
      }
    })
    .catch((error) => console.error("Error:", error));
};

const CommonMenuItems = (props) => {
  let { thing = {}, link = {}, index, variant, aspectRatio = 1 } = props;
  const { toast } = useToast();

  const userPlaylists = usePlaylistsStore((state) => state.userPlaylists);

  let asset = thing;
  if (Object.keys(link).length > 0) {
    asset = link;
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <UserPlus className="mr-2 h-4 w-4" />
          <span>Add to Collection</span>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent>
            {userPlaylists.map((c, i) => {
              return (
                <DropdownMenuItem key={i} onClick={() => addToCollection(c, asset, toast, ...props)}>
                  {asset.__typename == "Thing" && c.elements.some((element) => element?.id === asset.id) && <Check className="mr-2 h-4 w-4" />}
                  {asset.__typename == "Link" && c.links?.some((l) => l?.id === asset.id) && <Check className="mr-2 h-4 w-4" />}
                  <span>{c.name}</span>
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  );
};

export default CommonMenuItems;
