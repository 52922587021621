import { useState } from "react";
import { useStylesStore } from "../lib/stores/styles_store"; // Adjust the path as needed

const GlobalStylesForm = () => {
  const globalStyles = useStylesStore((state) => state.globalStyles);
  const setGlobalStyles = useStylesStore((state) => state.setGlobalStyles);

  // Define hardcoded style properties
  const [styleProperties, setStyleProperties] = useState({
    sidebar_bg: globalStyles.sidebar_bg || "",
    fontColor: globalStyles.fontColor || "",
    fontSize: globalStyles.fontSize || "",
    // Add more properties as needed
  });

  const handleStyleChange = (property, value) => {
    setStyleProperties({ ...styleProperties, [property]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setGlobalStyles({ ...globalStyles, ...styleProperties });
  };

  return (
    <div className="flex">
      <div className="w-[150px] h-full" style={{ backgroundColor: styleProperties.sidebar_bg }}></div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="sidebar_bg">Sidebar Background:</label>
          <input id="sidebar_bg" type="color" value={styleProperties.sidebar_bg} onChange={(e) => handleStyleChange("sidebar_bg", e.target.value)} />
        </div>
        <button type="submit">Update Styles</button>
      </form>
    </div>
  );
};

export default GlobalStylesForm;
