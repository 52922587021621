"use client";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
// const ResponsiveGridLayout = WidthProvider(Responsive);
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { useEffect, useRef, useState } from "react";
import BentoHeader from "./BentoHeader";
import BentoBox from "./bento_box";
import "./collection_bento.css";
import DropZone from "./DropZone";

export const bentoTypeFormats = {
  Thing: {
    w: 2,
    h: 4,
    minW: 2,
    minH: 2,
  },
  Link: {
    w: 4,
    h: 4,
    minW: 2,
    minH: 2,
  },
  Image: {
    w: 4,
    h: 4,
    minW: 2,
    minH: 2,
  },
  Post: {
    w: 6,
    h: 4,
    minW: 2,
    minH: 2,
  },
};

const CollectionBento = (props) => {
  const { toast } = useToast();
  const updatePlaylist = usePlaylistsStore((state) => state.upupdatePlaylistdatePlaylist);

  let { collection = {}, allElements: initialElements = [] } = props;
  // const [collection, setCollection] = useState(initialCollection);
  let initialLayouts = {};
  if (collection.layouts) {
    initialLayouts = JSON.parse(collection.layouts);
  }
  if (!initialLayouts.components) {
    initialLayouts.components = {};
  }
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg"); // default breakpoint
  const [editDraggable, setEditDraggable] = useState(true);
  const [allElements, setAllElements] = useState(initialElements);
  // const [layout, setLayout] = useState(initialLayout);
  const [layouts, setLayouts] = useState(initialLayouts);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  // const [layoutComponents, setLayoutComponents] = useState(initialLayouts);
  const [editMode, setEditMode] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    let updatedLayout = layouts[currentBreakpoint] || []; // Use initialLayout if it exists, otherwise start with an empty array.
    let update = false;
    allElements.forEach((item, index) => {
      const x = (index % BENTO_GRID_COLUMNS) * 2; // Assuming you want 4 items per row (adjust as needed).
      const y = Math.floor(index / BENTO_GRID_COLUMNS) * 2; // Calculate the row index.
      let type = item.__typename;
      console.log("bentoTypeFormats", bentoTypeFormats, type, item);
      let format = bentoTypeFormats[type] || { w: 2, h: 4 };
      updatedLayout.push({
        i: item.id,
        x,
        y,
        ...format,
      });
      update = true;
    });
    if (update) {
      setLayouts({ ...layouts, [currentBreakpoint]: updatedLayout });
    }
  }, []);

  const saveLayout = () => {
    let data = { playlistId: collection.id, layouts: JSON.stringify(layouts) };

    fetch("/api/library/collections/layout", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("Success:", JSON.stringify(response));

        toast({
          title: `Saved layouts`,
        });
      })
      .catch((error) => console.error("Error:", error));
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    if (!layouts?.[currentBreakpoint] || layouts?.[currentBreakpoint].length == 0) {
      organizeLayout();
    }
  }, []);

  const updateComponents = (itemId, content) => {
    setLayouts({ ...layouts, components: { ...layouts["components"], [itemId]: { ...layouts["components"]?.[itemId], ...content } } });
  };

  const deleteItem = async (itemId) => {
    if (!editMode) return;
    try {
      const response = await fetch("/api/library/collections/delete_item", {
        method: "DELETE",
        body: JSON.stringify({ playlistId: collection.id, thingId: itemId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const updatedCollection = { ...collection };
      if (updatedCollection.thingsConnection && updatedCollection.thingsConnection?.edges) {
        updatedCollection.thingsConnection.edges = updatedCollection.thingsConnection?.edges.filter((edge) => edge.node.id !== itemId);
      }
      updatePlaylist(updatedCollection);
      updatedCollection;
      const updatedLayout = layouts[currentBreakpoint].filter((item) => item.i !== itemId);
      setLayouts({ ...layouts, [currentBreakpoint]: updatedLayout });

      toast({
        title: `Thing deleted!`,
      });
      return true; // Or return responseData if you want to return the response from the server
    } catch (error) {
      console.error("Error:", error);
      return false; // Or return the error if you need to handle it later
    }
  };

  const BENTO_GRID_WIDTH = 1200;
  const BENTO_GRID_COLUMNS = 4;

  const organizeLayout = () => {
    let updatedLayout = []; // Use initialLayout if it exists, otherwise start with an empty array.
    let update = false;
    allElements.forEach((item, index) => {
      const x = (index % BENTO_GRID_COLUMNS) * 2; // Assuming you want 4 items per row (adjust as needed).
      const y = Math.floor(index / BENTO_GRID_COLUMNS) * 2; // Calculate the row index.

      let type = item.__typename;

      updatedLayout.push({
        i: item.id,
        x,
        y,
        ...bentoTypeFormats[type],
      });
      update = true;
    });
    if (update) {
      setLayouts({ ...layouts, [currentBreakpoint]: updatedLayout });
    }
  };

  const onLayoutChange = (layout, newLayouts) => {
    if (editMode) {
      // let updatedLayout = newLayouts[currentBreakpoint]?.map((item) => {
      //   // const breakpointComponents = layoutComponents[currentBreakpoint] || [];

      //   return {
      //     ...item,
      //     // components: breakpointComponents.find((i) => i.i === item.i)?.components
      //   };
      // });
      // setLayouts((prevLayouts) => ({
      //   ...prevLayouts,
      //   [currentBreakpoint]: updatedLayout,
      // }));
      setLayouts(newLayouts);
    }
  };

  const onBreakpointChange = (newBreakpoint) => {
    setCurrentBreakpoint(newBreakpoint);
    console.log(newBreakpoint, "BREAKKKKK");
  };

  return (
    <div ref={ref}>
      <DropZone collection={collection} disabled={editMode}>
        <Button onClick={organizeLayout}>Organize/Reset Layout</Button>
        <Button onClick={saveLayout}>Save Layout</Button>
        <Button onClick={() => setEditMode(!editMode)}>{editMode ? "Editing" : "Edit"}</Button>
        <Button onClick={() => setEditDraggable(!editDraggable)}>{editDraggable ? "Draggable" : "No drag"}</Button>
        <ResponsiveGridLayout className="layout" breakpoints={{ sm: 794, lg: 800 }} cols={{ sm: 4, lg: 8 }} margin={[40, 40]} onBreakpointChange={onBreakpointChange} rowHeight={60} width={width} layouts={layouts} isDraggable={editDraggable} isResizable={editDraggable} draggableCancel=".no-drag" draggableHandle=".draggable" onLayoutChange={onLayoutChange} measureBeforeMount={true}>
          <div key={"header"} data-grid={{ x: 0, y: 0, w: 2, h: 8, static: true }} className="overflow-hidden">
            <BentoHeader collection={collection} />
          </div>
          {allElements.map((item) => {
            console.log("ITE$EMMM", item.id);
            const currentLayout = layouts[currentBreakpoint];
            let itemLayout = currentLayout?.[currentLayout?.findIndex((i) => i.i == item.id)] || { x: 0, y: 0, w: 2, h: 4 };
            console.log("ITEM LAYOUT", { itemLayout, w: itemLayout.w, itemId: item.id });
            return (
              <div key={item.id} data-grid={itemLayout} className="draggable h-full rounded-3xl overflow-hidden shadow-lg" onMouseEnter={() => setHoveredItem(item.id)} onMouseLeave={() => setHoveredItem(null)} isResizable={item.id !== hoveredItem}>
                <BentoBox currentBreakpoint={currentBreakpoint} editMode={editMode} item={item} layout={itemLayout} components={layouts?.["components"]?.[item.id] || {}} updateComponents={updateComponents} deleteItem={deleteItem} />
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </DropZone>
    </div>
  );
};

export default CollectionBento;
