"use client";
import React, { useEffect, useState } from "react";
import { Post } from "./post";

const PostReplies = (props) => {
  let { post = {} } = props;
  let { replies = [] } = post;

  return (
    <div>
      {replies.map((reply) => {
        return <Post key={reply.id} post={reply} asChild />;
      })}
    </div>
  );
};

export default PostReplies;
