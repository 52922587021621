import { create } from "zustand";

export const useComposeStore = create((set) => ({
  isComposeOpen: false,
  things: [],
  addThing: (thing) => {
    if (thing.__typename === "Thing") {
      // console.log("THINGNGSSSS", state.things, things);
      set((state) => ({ things: [thing, ...state.things] }));
    }
  },
  removeThing: (index) => {
    set((state) => ({ things: state.things.filter((_, i) => i !== index) }));
  },
  openCompose: (thing) => {
    if (thing && thing.__typename === "Thing") {
      set({ isComposeOpen: true, things: [thing] });
    }
  },
  closeCompose: () => set({ isComposeOpen: false }),
  toggleCompose: (value) => {
    console.log("TOGGLE", value);
    set({ isComposeOpen: value });
  },
}));
