"use client";
import { getTypeFromThing } from "../../lib/utils";
import Search from "../Search/search";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../ui/dialog";
import { useSearchStore } from "@/lib/stores/search_store";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { useEffect, useState } from "react";
import { addToCollection } from "../Thing/common_menu_items";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "../ui/use-toast";
import { NewCollection } from "../Sidebar";
import { useCurrentStore } from "@/lib/stores/current_store";

export function CollectDialog(props) {
  let { collection, variant } = props;
  const [openDialog2, setOpenDialog2] = useState(null);
  const currentThing = useCurrentStore((state) => state.currentThing);
  const setSearchInput = useSearchStore((state) => state.setSearchInput);
  const searchInput = useSearchStore((state) => state.searchInput);

  useEffect(() => {
    if (!openDialog2) {
      setSearchInput({ ...searchInput, ["collection_dialog"]: "" });
    }
  }, [openDialog2]);
  return (
    <Dialog className="w-full md:w-[770px]" open={openDialog2} onOpenChange={setOpenDialog2}>
      <DialogTrigger asChild>
        <Button
          className={variant == "sidebar" ? "h-6 px-4 py-2 text-sm font-medium" : ""}
          variant="default"
          onClick={(e) => {
            setOpenDialog2(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          Collect {variant == "sidebar" ? (currentThing?.name?.length > 20 ? currentThing?.name?.slice(0, 20) + "..." : currentThing?.name) : ""}
        </Button>
      </DialogTrigger>
      <Collect collection={collection} useCurrentThing={true} />
    </Dialog>
  );
}
export function Collect(props) {
  let { collection: initialCollection = {}, useCurrentThing } = props;
  const userPlaylists = usePlaylistsStore((state) => state.userPlaylists);

  const searchResults = useSearchStore((state) => state.searchResults);
  const currentThing = useCurrentStore((state) => state.currentThing);
  const [collectionObjectId, setCollectionObjectId] = useState(initialCollection?.id);
  const { toast } = useToast();

  useEffect(() => {
    if (!collectionObjectId) {
      setCollectionObjectId(initialCollection?.id);
    }
  }, [initialCollection]);

  let collection = userPlaylists.find((p) => p.id == collectionObjectId) || {};

  return (
    <DialogContent className="sm:max-w-md md:max-w-4xl">
      <div className="grid grid-cols-1 md:grid-cols-2 ">
        <div className="flex flex-col items-start space-x-2 space-y-2 overflow-hidden mw-full">
          <div>
            <DialogTitle>{collection.name ? collection.name : "Select a Collection"}</DialogTitle>
            <DialogDescription>{collection.name ? `Search for items you'd like to add to ${collection.name}` : "First, select a collection, then search for items to add."}</DialogDescription>
            {useCurrentThing && (
              <div>
                {currentThing?.name}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addToCollection(collection, currentThing, toast);
                  }}
                  variant="secondary"
                  className="h-6 px-4 py-2 text-sm font-medium"
                >
                  Collect {useCurrentThing}
                </Button>
              </div>
            )}
            <Search variant="collection_dialog" location="collection_dialog" />
          </div>
          <div className="flex flex-col flex-1 gap-4 max-h-96 overflow-auto">
            <div className="flex flex-col gap-4">
              {searchResults["collection_dialog"]?.map((result, i) => {
                let type = getTypeFromThing(result);
                let hasCollected = collection?.elements?.some((element) => element.slug == result.slug);
                return (
                  <div className="grid grid-cols-5 w-full">
                    <img src={result.image} className="rounded-xs w-full col-span-1" width={"25%"} />
                    <div className="col-span-3">
                      <div className="text-md">{result.name}</div>
                      <div clssName="text-sm capitalize" variant="secondary">
                        {type}
                      </div>
                    </div>

                    <div className="col-span-1">
                      {!hasCollected && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            addToCollection(collection, result, toast);
                          }}
                          variant="secondary"
                          className="h-6 px-4 py-2 text-sm font-medium"
                        >
                          Collect
                        </Button>
                      )}
                      {hasCollected && "Collected"}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sm:justify-start">
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
          </div>
        </div>
        <div className="h-full">
          <div className="">
            <Select value={collectionObjectId} onValueChange={setCollectionObjectId}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a collection" />
              </SelectTrigger>
              <SelectContent>
                {/* <SelectGroup> */}
                {/* <SelectLabel>Collections</SelectLabel> */}
                {userPlaylists?.map((playlist, i) => {
                  return <SelectItem value={playlist.id}>{playlist.name}</SelectItem>;
                })}
                {/* </SelectGroup> */}
              </SelectContent>
            </Select>{" "}
            <Dialog>
              <DialogTrigger>
                <Button variant="secondary">New Collection</Button>
              </DialogTrigger>
              <DialogContent>
                <NewCollection />
              </DialogContent>
            </Dialog>
          </div>
          {Object.keys(collection).length != 0 && (
            <div>
              Items in the collection:
              {collection.elements?.length > 0 && (
                <div className="p-6 grid grid-cols-4 gap-4 max-h-96 overflow-auto">
                  {[...collection?.elements]
                    .reverse()
                    .filter((t) => t.__typename == "Thing")
                    .map((thing, i) => {
                      return <img src={thing.image} width="100%" className="rounded-xs z-30" />;
                    })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DialogContent>
  );
}
