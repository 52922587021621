import * as React from "react";

import { cn } from "@/lib/utils";

const Thing = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("bg-Thing text-Thing-foreground", className)} {...props} />);
Thing.displayName = "Thing";

const ThingHeader = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("flex flex-col space-y-1.5 p-2", className)} {...props} />);
ThingHeader.displayName = "ThingHeader";

const ThingTitle = React.forwardRef(({ className, ...props }, ref) => <span ref={ref} className={cn("text-sm leading-none tracking-tight", className)} {...props} />);
ThingTitle.displayName = "ThingTitle";

const ThingDescription = React.forwardRef(({ className, ...props }, ref) => <p ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />);
ThingDescription.displayName = "ThingDescription";

const ThingContent = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />);
ThingContent.displayName = "ThingContent";

const ThingFooter = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("flex items-center p-6 pt-0", className)} {...props} />);
ThingFooter.displayName = "ThingFooter";

export { Thing, ThingHeader, ThingFooter, ThingTitle, ThingDescription, ThingContent };
