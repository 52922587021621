import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useStylesStore = create(
  persist(
    (set, get) => ({
      globalStyles: {},
      setGlobalStyles: (newGlobalStyles) =>
        set({
          globalStyles: newGlobalStyles,
        }),
    }),
    {
      name: "globalStyles", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
