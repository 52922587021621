"use client";
import { Badge } from "@/components/ui/badge";
import { categoriesList } from "@/lib/categories";
import { getCallToActions } from "@/lib/helperFunctions";
import { useUpdateStage } from "@/lib/hooks/updateStage";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { getTypeFromThing } from "@/lib/utils";
import { Check } from "lucide-react";
import { Button } from "../ui/button";
import { DropdownMenuGroup, DropdownMenuItem } from "../ui/dropdown-menu";

const DisplayUserStage = (props) => {
  let { thing, index, variant, aspectRatio = 1 } = props;
  const stagePlaylists = usePlaylistsStore((state) => state.stagePlaylists);
  let currentStage = -1;
  let type = getTypeFromThing(thing);
  if (type in categoriesList) {
    let typeThingId = categoriesList[type]?.["thing"]?.["id"];
    let stagePlaylist = stagePlaylists.find((playlist) => playlist.thing.id === typeThingId) || {};
    let existingThingIndex = stagePlaylist?.things?.findIndex((t) => t.id === thing.id);
    if (existingThingIndex > -1) {
      currentStage = stagePlaylist?.["things"]?.[existingThingIndex]["current_stage"] ?? -1;
    }
  }

  let call_to_action = getCallToActions(categoriesList, thing);

  for (let c in call_to_action["menu"]) {
    call_to_action["menu"][c] = {
      ...call_to_action["menu"][c],
      actionKey: c,
      menuState: currentStage == c ? "on" : "off",

      actionTitle: currentStage == c ? call_to_action["menu"][c].text_active : call_to_action["menu"][c].text,
      logDate: call_to_action["menu"][c].logDate,
      isStage: true,
      icon: {
        type: "IMAGE_SYSTEM",
        imageValue: {
          systemName: call_to_action["menu"][c].icon,
        },
      },
    };
  }

  let menuItems = [...call_to_action["menu"]];
  let currentUser = { id: "YSCbEHFxm0gnXOPEKmRb8WUVO7j2", profile_picture: "https://fastly.picsum.photos/id/818/200/300.jpg?hmac=lE_Le7TxnELgojCX97DVlE9CLlZJWqnfbaQR3Chjstw", username: "danfein", name: "dan" };

  const [newCurrentStage, setNewCurrentStage] = useUpdateStage({ user: currentUser, thing, currentStage });

  if (variant == "dropdown") {
    return (
      <DropdownMenuGroup>
        {menuItems.map((item, i) => {
          return (
            <DropdownMenuItem onClick={() => setNewCurrentStage(item.actionKey)} key={i}>
              <>
                {currentStage == i && <Check />}
                <span>{item.text}</span>
              </>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuGroup>
    );
  } else if (variant == "log") {
    return (
      <div className="flex flex-row space-x-4">
        {menuItems.map((item, i) => {
          return (
            <Button onClick={() => setNewCurrentStage(item.actionKey)} key={i}>
              {currentStage == i && <Check />}
              <span>{item.text}</span>
            </Button>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Badge variant="outline">{menuItems[currentStage]?.["actionTitle"]}</Badge>
    </div>
  );
};

export default DisplayUserStage;
