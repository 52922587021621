import * as React from "react";

import { cn } from "@/lib/utils";

const PostCard = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("border-0 border-b border-slate-100 bg-card text-card-foreground  p-0 pt-3 pb-3 space-y-2", className)} {...props} />);
PostCard.displayName = "PostCard";

const ActionPostCard = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("border bg-card text-card-foreground shadow-2xs p-6 space-y-3", className)} {...props} />);
PostCard.displayName = "ActionPostCard";

const PostCardHeader = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("flex flex-col", className)} {...props} />);
PostCardHeader.displayName = "PostCardHeader";

const PostCardTitle = React.forwardRef(({ className, ...props }, ref) => <h3 ref={ref} className={cn("text-base font-semibold leading-none", className)} {...props} />);
PostCardTitle.displayName = "PostCardTitle";

const PostCardDescription = React.forwardRef(({ className, ...props }, ref) => <p ref={ref} className={cn("text-sm text-primary-subtitle leading-none", className)} {...props} />);
PostCardDescription.displayName = "PostCardDescription";

const PostCardContent = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("pt-3 space-y-3", className)} {...props} />);
PostCardContent.displayName = "PostCardContent";

const PostCardFooter = React.forwardRef(({ className, ...props }, ref) => <div ref={ref} className={cn("flex items-center pt-3", className)} {...props} />);
PostCardFooter.displayName = "PostCardFooter";

export { PostCard, ActionPostCard, PostCardHeader, PostCardFooter, PostCardTitle, PostCardDescription, PostCardContent };
