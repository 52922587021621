"use client";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, getTypeFromThing } from "../../lib/utils";
import { CollectionInput, Input } from "../ui/input";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "@/components/ui/command";
import Link from "next/link";
import { redirect, useRouter } from "next/navigation";
import useSWRImmutable from "swr/immutable";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Box, Search as SearchIcon } from "lucide-react";
import { usePathname } from "next/navigation";
import { useSearchStore } from "@/lib/stores/search_store";
import { LogoSVG } from "../Sidebar";

const Search = (props) => {
  let { variant, initialSearch, location = "" } = props;
  // if (variant && !location) location = variant;
  const searchInput = useSearchStore((state) => state.searchInput);
  const setSearchInput = useSearchStore((state) => state.setSearchInput);
  const searchResults = useSearchStore((state) => state.searchResults);
  const setSearchResults = useSearchStore((state) => state.setSearchResults);
  const [isOpen, setIsOpen] = useState(false);
  const [searchUrl, setSearchUrl] = useState({});
  const pathname = usePathname();
  const { data: searchQueryResults = [], error: searchError } = useSWRImmutable(
    searchUrl[location],
    fetcher
  );
  const router = useRouter();

  useEffect(() => {
    if (
      location &&
      typeof searchInput === "object" &&
      !(location in searchInput)
    ) {
      setSearchInput({ ...searchInput, [location]: "" });
    }
  }, [location]);

  useEffect(() => {
    if (searchInput[location]?.length > 0) {
      setSearchUrl({
        ...searchUrl,
        [location]: `/api/search?searchString=${searchInput[location]}`,
      });
    } else {
      setSearchUrl({ ...searchUrl, [location]: null });
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchQueryResults.length > 0) {
      console.log("SET SEARCH Q RESULTS", searchQueryResults);
      return setSearchResults({
        ...searchResults,
        [location]: searchQueryResults,
      });
    }
  }, [searchQueryResults]);

  // useEffect(() => {
  //   console.log("INITIALSEARCHHHH", initialSearch);
  //   if (initialSearch) {
  //     setSearchInput(initialSearch.replace("-", " "));
  //   }
  // }, [initialSearch]);

  const onResultClick = (thing) => {
    if (props.onResultClick) {
      props.onResultClick(thing);
    } else {
      let link = `/t/${thing?.type?.slug}/${thing.slug}`;
      router.push(link);
    }
    setIsOpen(false);
  };

  return (
    <>
      {/* <Input onFocus={() => setIsOpen(true)} className="w-full bg-white shadow-none appearance-none dark:bg-zinc-950" placeholder="Search..." type="search" /> */}

      {variant == "nav" && (
        <div>
          {pathname !== "/explore" && (
            <div>
              <Link href="/explore" className="nav-button" prefetch={true}>
                <SearchIcon className="nav-button-icon" />
                Search
              </Link>
            </div>
          )}
          {pathname == "/explore" && (
            <div>
              <div className="nav-button bg-white">
                <SearchIcon className="nav-button-icon" />
                <Input
                  placeholder="Search"
                  className="border-0 outline-hidden"
                  autoFocus={true}
                  value={searchInput[location]}
                  onChange={(e) =>
                    setSearchInput({
                      ...searchInput,
                      [location]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
      {variant == "collection_dialog" && (
        <div className="nav-button bg-white mr-2 mt-2">
          <SearchIcon className="nav-button-icon" />
          <CollectionInput
            placeholder="Search"
            className="border-0 outline-hidden"
            autoFocus={true}
            value={searchInput[location]}
            onChange={(e) =>
              setSearchInput({ ...searchInput, [location]: e.target.value })
            }
          />
        </div>
      )}
      {variant == "compose" && (
        <Button variant="ghost" size="icon" onClick={() => setIsOpen(true)}>
          {/* <Box className="w-5 h-5" /> */}
          <LogoSVG className="w-6 h-6" />
        </Button>
      )}
      {variant == "compose_inline" && props.showSuggestions && (
        <div>
          <ul className="border p-2 bg-white w-full">
            {searchResults?.[location]?.map((result, i) => {
              let type = getTypeFromThing(result);
              return (
                <Button
                  value={result.name}
                  key={i}
                  onClick={() => onResultClick(result)}
                  className="flex flex-row w-full  justify-between items-start"
                >
                  <div>
                    <div className="text-md">{result.name}</div>
                  </div>
                  <div>
                    <Badge className="text-sm capitalize" variant="secondary">
                      {type}
                    </Badge>
                  </div>
                </Button>
              );
            })}
          </ul>
        </div>
      )}
      <CommandDialog
        open={isOpen}
        onOpenChange={setIsOpen}
        style={{ zIndex: 1000000, backgroundColor: "red" }}
      >
        <CommandInput
          placeholder="Type a command or search..."
          value={searchInput[location]}
          onValueChange={(value) =>
            setSearchInput({ ...searchInput, [location]: value })
          }
        />

        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {/* {searchResults.length > 0 && ( */}
          <CommandGroup heading="Results">
            <CommandItem>Search above</CommandItem>
            {searchResults?.[location]?.map((result, i) => {
              let type = getTypeFromThing(result);
              return (
                <CommandItem
                  value={result.name}
                  key={i}
                  onSelect={() => onResultClick(result)}
                  className="flex flex-row w-full  justify-between items-start"
                >
                  <div>
                    <div className="text-md">{result.name}</div>
                  </div>
                  <div>
                    <Badge className="text-sm capitalize" variant="secondary">
                      {type}
                    </Badge>
                  </div>
                </CommandItem>
              );
            })}
          </CommandGroup>
          {/* )} */}
        </CommandList>
      </CommandDialog>
    </>
  );
};

export default Search;
