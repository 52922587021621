import { useToast } from "@/components/ui/use-toast";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { extractLinks, getTypeFromThing, trimUrl } from "@/lib/utils";
import { Box, Check, Shapes } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { NextResponse } from "next/server";
import { useEffect, useState } from "react";
import { LogoSVG } from "../Sidebar";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "../ui/input";
import { useSearchStore } from "@/lib/stores/search_store";
import Search from "../Search/search";
import { Badge } from "../ui/badge";
import { Collect, CollectDialog } from "./Collect";
import { useParams, usePathname } from "next/navigation";
import { useComposeStore } from "@/lib/stores/compose_store";
import { useCurrentStore } from "@/lib/stores/current_store";
import { NavLink } from "../ui/nav_link";

export const handleOnDrag = (e, content) => {
  e.stopPropagation();
  console.log("HANDLEEE", e, content);
  e.dataTransfer.setData("item", JSON.stringify(content));
};

export const initiateUpdateLink = async (res, url, id) => {
  let last_updated = null;
  if (res?.links?.length > 0) {
    //Fetch the api to check the metadata info and update it
    last_updated = res.links[0];
  }
  // if (!last_updated) return; //Temporarily hide
  return fetch(`/api/update_link?id=${id}&url=${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      console.log("Success:", JSON.stringify(response));
      return NextResponse.json({ success: true });
    })
    .catch((error) => console.error("Error:", error));
};

const DisplayCollectionRow = (props) => {
  let pathname = usePathname();
  let { slug } = useParams();
  let { index, overlap, variant, collection: initialCollection, format, isStageCollection, likedCollections } = props;
  const userPlaylists = usePlaylistsStore((state) => state.userPlaylists);
  const incrementLinkCount = usePlaylistsStore((state) => state.incrementLinkCount);
  const searchInput = useSearchStore((state) => state.searchInput);
  const setSearchInput = useSearchStore((state) => state.setSearchInput);
  const searchResults = useSearchStore((state) => state.searchResults);

  let collection = userPlaylists.find((p) => p.id == initialCollection.id) || initialCollection;
  if (isStageCollection) {
    collection = initialCollection;
  }
  const addElementToPlaylist = usePlaylistsStore((state) => state.addElementToPlaylist);

  const [openDialog, setOpenDialog] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    if (!openDialog) {
      setSearchInput({ ...searchInput, ["collection_dialog"]: "" });
    }
  }, [openDialog]);

  const handleCheckboxChange = (option) => {
    console.log("HANDLE CHANGE", option);
    setFilterOptions((prev) => ({ ...prev, [option]: !prev[option] }));
  };
  let allElements = collection.elements || [];
  const handleOnDrop = async (e) => {
    let itemDropped, arbitraryDrop;
    e.preventDefault();
    const itemData = e.dataTransfer.getData("item");
    try {
      itemDropped = JSON.parse(itemData);
    } catch (error) {
      console.error("Error parsing 'item' data:", error);
    }
    let type = itemDropped?.["__typename"] || "";
    if (!itemData || type == "Link") {
      let { types } = e.dataTransfer;
      console.log("TYPPP");
      if (types.length == 0) return null;
      arbitraryDrop = e.dataTransfer.getData(types[0]);
      // let links = extractLinks(arbitraryDrop);
      let links = [];
      if (type == "Link") {
        links = [itemDropped.url];
      }
      if (links.length > 0) {
        let url = trimUrl(links[0]);
        let data = { collectionId: collection.id, url };
        console.log("DDD", data);
        fetch("/api/library/collections/add_url", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(async (response) => {
            console.log("Success:", JSON.stringify(response));
            let { id } = response?.links?.[0] || {};
            let link_count = collection.link_count || 0;
            if (link_count == response.link_count) {
              toast({
                title: `${url} already in ${collection.name}`,
                style: { color: "red" },
              });
            } else {
              incrementLinkCount(collection.id);
              toast({
                title: `Saved ${url} to ${collection.name}`,
              });
            }
            setIsHovered(false);
            let initiate = await initiateUpdateLink(response, url, id);
            console.log("initiate", initiate);
          })
          .catch((error) => console.error("Error:", error));
      }
    } else if (itemDropped) {
      setIsHovered(false);
      setHoveredType(type);
      let data = { playlistId: collection.id, type };
      const thingId = itemDropped.id;
      data["assetId"] = thingId;
      if (!isStageCollection) {
        fetch("/api/library/collections", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("Success adding:", JSON.stringify(response));
            let success = true;
            let thing_count = response.thing_count || 0;
            let image_count = response.image_count || 0;
            let post_count = response.has_post_count || 0;

            if (type == "Thing") {
              if (thing_count == collection.thing_count) {
                toast({
                  title: `${itemDropped.name} already in ${collection.name}`,
                  style: { color: "red" },
                });
                success = false;
              }
            } else if (type == "Image") {
              if (image_count == collection.image_count) {
                toast({
                  title: `Image already in ${collection.name}`,
                  style: { color: "red" },
                });
                success = false;
              }
            } else if (type == "Post") {
              if (post_count == collection.post_count) {
                toast({
                  title: `Post already in ${collection.name}`,
                  style: { color: "red" },
                });
                success = false;
              }
            }
            if (success) {
              toast({
                title: `Added ${type} to ${collection.name}`,
              });
              console.log("ADDING ELEMENT TO PLAYLIST", collection.id, itemDropped.id);
              addElementToPlaylist(collection.id, itemDropped);
            }
          })
          .catch((error) => console.error("Error:", error));
      } else {
        console.log("DO SOMETHING WIHT THIS FOR STAGE COLLECTIONS!,", itemDropped);
        setLogOpen(true);
        setLogThing(itemDropped);
      }
    } else {
      // Handle the case where itemDropped is undefined
      console.log("No item was dropped");
    }
  };

  const handleDragOver = (e, i) => {
    e.preventDefault();
    setIsHovered(true);
    try {
      let draggedData = e.dataTransfer.types;
      if (draggedData.length < 3) {
        setHoveredType(null);
        return;
      } else {
        setHoveredType(draggedData[draggedData.length - 1]);
      }
    } catch (error) {
      console.log("ERROR", error);
      setHoveredType(true);
    }
  };

  const handleDragLeave = (e, i) => {
    e.preventDefault();
    setIsHovered(false);
    setHoveredType(null);
  };

  let { element_count = 0 } = collection;
  let hasCollected = !!slug && collection.elements?.some((element) => element.slug == slug);
  return (
    <div
      onDrop={(e) => handleOnDrop(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDragOver={(e) => handleDragOver(e)}
      draggable
      onDragStart={(e) => handleOnDrag(e, collection)}
      className={`relative group 
      space-y-1
      ${isHovered ? "border-4" : "border-transparent"} 
    ${hoveredType == "thing" ? "border-cyan-500" : hoveredType == "image" ? "border-green-500" : hoveredType == null && isHovered ? "border-yellow-500" : "transparent"}`}
    >
      {isHovered && hoveredType && (
        <div className="absolute h-full w-full bg-green-500 justify-center items-center align-middle">
          Dropping {hoveredType} into {collection.name}
        </div>
      )}
      <NavLink href={`/${collection?.owner?.username}/${collection.slug}`} variant="ghost">
        {/* <img src={allElements?.[0]?.image} width={16} height={16} style={{ maxWidth: 16, maxHeight: 16, borderRadius: 4, backgroundColor: "lightgray" }} className="aspect-square object-cover max-w" /> */}
        <Shapes size={16} className="w-4 h-4" />
        <div className="flex flex-row justify-between w-full">
          <h3 className="font-semibold text-sm w-full">{collection.name}</h3>
          {hasCollected && (
            <p className={`text-xs ${isHovered ? "text-green-500" : "text-zinc-500"} dark:text-zinc-400 flex flex-row gap-1`}>
              <Check size={16} className="mr-2" />
            </p>
          )}
          {element_count > 0 && (
            <p className="text-xs text-zinc-500 dark:text-zinc-400 flex flex-row gap-1">
              {element_count}
              <LogoSVG className="w-5 h-5 " />
            </p>
          )}
        </div>
      </NavLink>
      {!isHovered && !hasCollected && !likedCollections && (
        <div className="absolute h-full flex items-center justify-end w-20 opacity-0 gap-2 group-hover:opacity-100 transition-opacity right-0 top-0">
          <Dialog className="w-full md:w-[770px]" open={openDialog} onOpenChange={setOpenDialog}>
            <DialogTrigger asChild>
              <Button
                className="h-6 px-4 py-2 text-sm font-medium"
                variant="default"
                onClick={(e) => {
                  setOpenDialog(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Collect
              </Button>
            </DialogTrigger>
            <Collect collection={collection} />
          </Dialog>

          {!!slug && pathname?.includes("/t/") && <CollectDialog collection={collection} variant="sidebar" />}
        </div>
      )}
    </div>
  );
};

export default DisplayCollectionRow;
