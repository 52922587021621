"use client";
import CollectionBento from "@/components/Collections/bento/collection_bento";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { Box, Edit, ImageIcon, LinkIcon } from "lucide-react";
import { NextResponse } from "next/server";
import { useState } from "react";
import DisplayThing from "../Thing/DisplayThing";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { useParams, useRouter } from "next/navigation";
import CollectionInteraction from "@/components/Collections/collection_interaction";

export const handleOnDrag = (e, content) => {
  e.stopPropagation();
  e.dataTransfer.setData("item", JSON.stringify(content));
};

export const initiateUpdateLink = async (res, url, id) => {
  let last_updated = null;
  if (res?.links?.length > 0) {
    //Fetch the api to check the metadata info and update it
    last_updated = res.links[0];
  }
  // if (!last_updated) return; //Temporarily hide
  return fetch(`/api/update_link?id=${id}&url=${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      console.log("Success:", JSON.stringify(response));
      return NextResponse.json({ success: true });
    })
    .catch((error) => console.error("Error:", error));
};

const DisplayUserCollection = (props) => {
  let { index, overlap, variant, collection: initialCollection = {}, format, isStageCollection } = props;
  const userPlaylists = usePlaylistsStore((state) => state.userPlaylists);
  let collection = userPlaylists.find((p) => p.id == initialCollection.id) || {};
  if (isStageCollection) {
    collection = initialCollection;
  }

  collection = { ...initialCollection, current_stage: collection.current_stage };
  const [updateLayoutOnChange, setUpdateLayoutOnChange] = useState(false);
  const router = useRouter();
  const { vars } = useParams();
  let id = vars[0] || "list";
  let collectionFormat = vars[1] || "list";
  // const [collectionFormat, setCollectionFormat] = useState("bento");
  // const collectionFormat = router.query.format || "bento";
  const [filterOptions, setFilterOptions] = useState({
    things: true,
    images: true,
    links: true,
    posts: true,
  });
  const handleCheckboxChange = (option) => {
    console.log("HANDLE CHANGE", option);
    setFilterOptions((prev) => ({ ...prev, [option]: !prev[option] }));
  };
  let allElements = collection.elements || [];

  const handleButtonClick = () => {
    const newFormat = collectionFormat === "board" ? "list" : collectionFormat === "list" ? "compact" : "board";
    router.push(`/${collection.owner.username}/${id}/${newFormat}`);
  };

  let { isRanked } = collection;
  console.log("ALLEELEMENTS", { allElements, collection });
  if (variant == "collection_page") {
    return (
      <div>
        <Button onClick={handleButtonClick} className="capitalize">
          {collectionFormat}
        </Button>

        <CollectionInteraction collection={collection} />

        {collectionFormat == "board" && (
          <div>
            <div>
              <div className="flex flex-row space-x-4">
                {!!collection.thing_count && (
                  <p className="text-xs text-zinc-500 dark:text-zinc-400">
                    <Checkbox checked={filterOptions.things} onCheckedChange={() => handleCheckboxChange("things")} updateLayoutOnChange>
                      Things
                    </Checkbox>
                    {collection.thing_count} thing{collection.thing_count == 1 ? "" : "s"}
                    <Box />
                  </p>
                )}
                {!!collection.link_count && (
                  <p className="text-xs text-zinc-500 dark:text-zinc-400">
                    <Checkbox checked={filterOptions.links} onCheckedChange={() => handleCheckboxChange("links")}>
                      Links
                    </Checkbox>
                    {collection.link_count} link{collection.link_count == 1 ? "" : "s"}
                    <LinkIcon />
                  </p>
                )}
                {!!collection.image_count && (
                  <p className="text-xs text-zinc-500 dark:text-zinc-400">
                    <Checkbox checked={filterOptions.images} onCheckedChange={() => handleCheckboxChange("images")}>
                      Images
                    </Checkbox>
                    {collection.image_count} image{collection.image_count == 1 ? "" : "s"}
                    <ImageIcon />
                  </p>
                )}

                {!!collection.post_count && (
                  <p className="text-xs text-zinc-500 dark:text-zinc-400">
                    <Checkbox checked={filterOptions.posts} onCheckedChange={() => handleCheckboxChange("posts")}>
                      Posts
                    </Checkbox>
                    {collection.post_count} post{collection.post_count == 1 ? "" : "s"}
                    <Edit />
                  </p>
                )}
              </div>
            </div>

            <CollectionBento collection={collection} allElements={allElements} updateLayoutOnChange={updateLayoutOnChange} setUpdateLayoutOnChange={setUpdateLayoutOnChange} />
          </div>
        )}
        {collectionFormat == "compact" && (
          <div>
            {/* {isRanked && ( */}
            <div className="flex flex-wrap">
              {collection.elements.map((thing, i) => {
                // let { node: thing } = e;
                return <DisplayThing thing={thing} variant="collection_page_compact" order={i + 1} isRanked={isRanked} />;
              })}
            </div>
            {/* )} */}
            <div className="w-full">
              {collection.images?.map((image, i) => {
                return <img src={image.url} key={i} />;
              })}
            </div>
          </div>
        )}
        {collectionFormat == "list" && (
          <div>
            <div className="w-full space-y-6">
              {collection.elements.map((thing, i) => {
                // let { node: thing } = e;
                if (thing.__typename == "Thing") {
                  return <DisplayThing thing={thing} variant="collection_page" />;
                } else if (thing.__typename == "Image") {
                  return (
                    <div>
                      <img src={thing.url} width="50" />
                    </div>
                  );
                } else if (thing.__typename == "Link") {
                  return <a>{thing.url}</a>;
                } else {
                  return <div>Unknown type{thing.__typename}</div>;
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
  return <div>Display Collection</div>;
};

export default DisplayUserCollection;
