"use client";
import DisplayThing from "@/components/Thing/DisplayThing";
import DisplayLink from "@/components/Thing/DisplayLink";
import "./collection_bento.css";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { handleOnDrag } from "@/components/Collections/display_user_collection";
import { AlignLeft, AlignRight, AlignJustify, AlignCenter } from "lucide-react";
import Draggable, { DraggableCore } from "react-draggable";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Fragment, useState } from "react";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { hexToRgbA } from "@/lib/helperFunctions";
import { Textarea } from "@/components/ui/textarea";
import { Badge } from "@/components/ui/badge";
import { Post } from "@/components/Post/post";

const BentoParent = (props) => {
  let { item, children, addText, variant, index, setVariant, editMode } = props;

  let { layout = {}, updateComponents, deleteItem } = props;
  let { components = {} } = layout;
  let { text = [] } = components;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Popover>
      <div draggable className="overflow-hidden h-full rounded-2xl border-transparent" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {editMode && isHovered && (
          <div className="absolute top-0 z-13 bg-white border-black">
            <PopoverTrigger className="" onClick={() => setVariant("thing")}>
              Thing Style
            </PopoverTrigger>
            <PopoverTrigger className="" onClick={addText}>
              Add Text
            </PopoverTrigger>

            {/* {text.map((t, i) => {
          return (
            <PopoverTrigger
              className=""
              key={i}
              onClick={() => {
                setIndex(i);
                setVariant("text");
              }}
            >
              Edit {t.text}
            </PopoverTrigger>
          );
        })} */}
            <PopoverTrigger className="">Open</PopoverTrigger>
          </div>
        )}

        <PopoverContent side="right" align="start">
          <StyleEditor variant={variant} index={index} item={item} {...props} />
        </PopoverContent>

        {children}
      </div>
    </Popover>
  );
};

const BentoText = (props) => {
  let { text = {}, index, setIndex, setVariant } = props;
  const formattedText = text.text?.split("\n").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
  let fontSize = text.style?.fontSize || 16;
  let componentStyle = { ...text.style, fontSize: fontSize.toString() + "rem" };
  console.log("SHOW STYLE", text, componentStyle);
  return (
    // <div className="w-full" style={text.style}>
    <PopoverTrigger
      style={componentStyle}
      className="block w-full bg-transparent pointer-events-auto leading-tight"
      onClick={() => {
        setIndex(index);
        setVariant("text");
      }}
    // dangerouslySetInnerHTML={{ __html: text.text || "Click to Edit" }}
    >
      {/* Edit {t.text} */}
      {/* ); */}
      {/* })} */}
      {text.text ? formattedText : "Click to Edit"}
    </PopoverTrigger>
    // </div>
  );
};

const BentoImage = (props) => {
  let { image = {}, editMode = false, item, updateComponents } = props;

  return (<div className="relative flex flex-col justify-center items-center h-full z-8">
    {/* {item.id} */}
    <img src={image.url} width="100%" height="100%" style={{ pointerEvents: "none", objectFit: "cover" }} />
  </div>)
};

const BentoBackgroundImage = ({ children }) => {
  return <div className="absolute h-full w-full">{children}</div>;
};

const BentoThing = (props) => {
  let { thing = {}, editMode = false, item, updateComponents } = props;

  return (
    <div className="relative flex flex-col justify-center items-center h-full z-8">
      {/* {item.id} */}
      <img src={item.image} className={`${thing.className ? thing.className : ""} w-full h-full`} style={{ pointerEvents: "none", objectFit: "cover" }} />
    </div>
  );
};

const BentoLink = (props) => {
  let { link = {}, editMode = false, item, updateComponents } = props;
  let { metadata = {} } = link;
  let url = item.url;
  if (url) {
    console.log("URLLL", item.url);
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    console.log("FINDDD ", url);
    try {
      url = new URL(url).hostname;
    } catch {
      console.log("ERROR WITH URL", url);
      return null;
    }
  }
  return (
    <div className="relative flex flex-col justify-between h-full z-8 p-4">
      <div>
        {metadata?.favicon && <img src={metadata?.favicon} className={link.className} style={{ width: 40, height: 40, borderRadius: 10 }} />}
        {metadata?.title && <div className="text-sm font-medium leading-none">{metadata?.title}</div>}
        {url && <div className="text-sm font-medium leading-none text-slate-600">{url}</div>}
      </div>
      {/* {item.url} */}
      {/* youtube? show youtube viemo? show vimeo */}

      <img src={metadata?.image} className={link.className} style={{ pointerEvents: "none", objectFit: "cover", borderRadius: 12 }} />
    </div>
  );
};

const BentoPost = (props) => {
  let { post = {}, editMode = false, item, updateComponents } = props;
  return (
    <div className="relative flex flex-col justify-center items-center h-full z-8">
      <Post post={post} variant="bento" />
    </div>
  );
};

const BentoInfo = (props) => {
  let { item } = props;
  return (
    <div className="absolute bottom-0 w-full p-4 z-11 bg-red pointer-events-none" style={{ minHeight: 25 }}>
      <div className=" space-x-4 rounded-md border px-4 py-3 w-full bg-white z-12 text-black h-full">
        {item.__typename == "Thing" && <DisplayThing thing={item} variant="bento_info" {...props} />}
        {item.__typename == "Link" && <DisplayLink link={item} variant="bento_info" {...props} />}
        {item.__typename == "Image" && <div>{item.id}</div>}
      </div>
      {/* a{item.__typename == "Thing" && <DisplayUserStage thing={item} />} */}
    </div>
  );
};

const StyleEditor = ({ variant, index, item, updateComponents, layout = {}, components }, ...props) => {
  let { text = [], overlay } = components;
  console.log("ALL TExT", text, index);
  let currentText;
  if (variant == "text" && text[index]) {
    console.log("SETTING CURRENT TEXT", text[index]);
    currentText = text[index];
  }

  const handleTextStyleChange = (e) => {
    let currentStyle = components["text"][index]?.["style"] || {};
    let value = e.target.value;
    if (e.target.type == "number") {
      value = parseFloat(e.target.value);
    }
    console.log("NOW I HAVE A VALUE OF", value);
    const updatedStyle = { ...currentStyle, [e.target.name]: value };
    let updatedText = [...components["text"]];
    updatedText[index] = { ...updatedText[index], style: { ...updatedStyle } };
    console.log("UPDATING index", index, updatedText[index]);
    updateComponents(item.id, { text: updatedText });
  };

  const handleOverlayStyleChange = (e) => {
    if (e == "clear") {
      let updatedOverlay = { ...components["overlay"], style: { ...{} } };
      updateComponents(item.id, { overlay: updatedOverlay });
    } else {
      let currentStyle = components?.["overlay"]?.["style"] || {};
      let value = e.target.value;
      const updatedStyle = { ...currentStyle, [e.target.name]: value };
      let updatedOverlay = { ...components["overlay"], style: { ...updatedStyle } };
      updateComponents(item.id, { overlay: updatedOverlay });
    }
  };

  const handlePureTextStyleChange = (style, value) => {
    let currentStyle = components["text"][index]?.["style"] || {};
    const updatedStyle = { ...currentStyle, [style]: value };
    let updatedText = [...components["text"]];
    updatedText[index] = { ...updatedText[index], style: { ...updatedStyle } };
    updateComponents(item.id, { text: updatedText });
  };

  const handleTextChange = (newText) => {
    console.log("NEW TEXT", newText, components);
    let updatedText = [...components["text"]];
    updatedText[index] = { ...updatedText[index], text: newText };
    updateComponents(item.id, { text: updatedText });
  };

  const deleteText = (index) => {
    let updatedText = [...components["text"]];
    updatedText.splice(index, 1);
    updateComponents(item.id, { text: updatedText });
  };

  const handleSizeChange = (size, className) => {
    updateComponents(item.id, { thing: { ...components["thing"], ...{ className, size } } });
  };

  if (variant == "thing") {
    console.log("SHOOOOW THING!!!");
    return (
      <div className="space-y-2">
        <div>
          <Label htmlFor="backgroundColor">Overlay Color</Label>
          <Input name="backgroundColor" type="color" onChange={handleOverlayStyleChange} value={overlay?.["style"]?.["backgroundColor"]} />
          {overlay?.["style"]?.["backgroundColor"] && <Button onClick={() => handleOverlayStyleChange("clear")}>Clear</Button>}
        </div>
        <RadioGroup defaultValue="large">
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="small" id="small" onClick={() => handleSizeChange("small", "w-[25%]")} />
            <Label htmlFor="small">Small</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="medium" id="medium" onClick={() => handleSizeChange("medium", "w-[50%]")} />
            <Label htmlFor="medium">Medium</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="large" id="large" onClick={() => handleSizeChange("large", "h-full w-full")} />
            <Label htmlFor="large">Large</Label>
          </div>
        </RadioGroup>
      </div>
    );
  } else if (variant == "text") {
    return (
      <div className="space-y-2">
        {
          <div>
            {index} {currentText?.text}
          </div>
        }
        <div className="flex flex-row items-center justify-between rounded-lg border p-4">
          <div className="space-y-0.5">
            <div className="text-base capitalize">Text</div>
            <div>
              <Textarea placeholder="New text" className={currentText?.style} value={currentText?.text} onChange={(e) => handleTextChange(e.target.value)} />
              <div>
                <Label htmlFor="fontSize">Font Size</Label>
                <Input name="fontSize" type="number" placeholder="Font Size" step=".25" onChange={handleTextStyleChange} value={parseFloat(currentText?.["style"]?.["fontSize"])} />
              </div>
              <div>
                <Label htmlFor="color">Color</Label>
                <Input name="color" type="color" onChange={handleTextStyleChange} value={currentText?.["style"]?.["color"]} />
              </div>

              <div>
                <Label htmlFor="fontWeight">Font Weight</Label>
                <Input name="fontWeight" type="number" placeholder="Font Weight" onChange={handleTextStyleChange} value={currentText?.["style"]?.["fontWeight"]} />
              </div>
              <div>
                <Label htmlFor="transform">Rotation</Label>
                <Input name="transform" type="text" placeholder="Rotation" onChange={handleTextStyleChange} value={currentText?.["style"]?.["transform"]} />
              </div>
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline">Text Align {currentText?.["style"]?.["textAlign"]}</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuRadioGroup value={currentText?.["style"]["textAlign"]} onValueChange={(value) => handlePureTextStyleChange("textAlign", value)}>
                      <DropdownMenuRadioItem value="left">
                        <AlignLeft />
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="center">
                        <AlignCenter />
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="right">
                        <AlignRight />
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="justify">
                        <AlignJustify />
                      </DropdownMenuRadioItem>
                      {/* <DropdownMenuRadioItem value="space-between">Space Between</DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="space-around">Space Around</DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="space-evenly">Space Evenly</DropdownMenuRadioItem> */}
                    </DropdownMenuRadioGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Button variant="ghost" onClick={() => deleteText(index)}>
                  Delete Text
                </Button>
              </div>
              {/* <div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline">Vertical {currentText["style"]["alignItems"]}</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuRadioGroup value={currentText["style"]["alignItems"]} onValueChange={(value) => handlePureTextStyleChange("alignItems", value)}>
                      <DropdownMenuRadioItem value="flex-start">Start</DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="center">Center</DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="flex-end">End</DropdownMenuRadioItem>
                    </DropdownMenuRadioGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div> */}
            </div>
          </div>
          {/* <Switch checked={has_header} onCheckedChange={toggleHeaderDescription} /> */}
        </div>
      </div>
    );
  }
  return (
    <div className="space-y-2">
      <Button variant="ghost" onClick={() => deleteItem(item.id)}>
        Delete Item
      </Button>
      <Button variant="outline">Close </Button>

      {/* Add more style properties here */}
    </div>
  );
};
const eventLogger = (e, data) => {
  console.log("Event: ", e);
  console.log("Data: ", data);
};
const BentoBox = (props) => {
  let { editMode = false, item, layout = {}, updateComponents, components } = props;
  let { thing = {}, image, text = [], link, overlay = {} } = components;
  const [variant, setVariant] = useState("");
  const [index, setIndex] = useState(0);

  const addText = (key, value) => {
    console.log("ADD TEXT!", components);
    let newText = components["text"] || [];
    newText.push({ text: "", style: { fontSize: 2, fontWeight: 400, color: "teal" } });
    console.log("ADDING COMPNOENTS", newText);
    updateComponents(item.id, { text: newText });
    setIndex(newText.length - 1);
    setVariant("text");
  };
  // console.log("BENTO THING", item);
  return (
    // <DraggableCore onDrag={eventLogger}>
    <div className="h-full" draggable {...(!editMode && { onDragStart: (e) => handleOnDrag(e, item) })}>
      <div className="relative h-full ">
        <BentoParent className="p-10" addText={addText} editMode={editMode} variant={variant} index={index} setVariant={setVariant} {...components} {...props}>
          {/* {JSON.stringify(thing)} */}
          <BentoBackgroundImage>
            {!!overlay?.style?.backgroundColor && <div className="absolute top- left-0 w-full h-full bg-opacity-50" style={{ zIndex: 2, backgroundColor: hexToRgbA(overlay?.style?.backgroundColor, 0.4) }}></div>}
            <div className="overflow-hidden h-full">
              <div className="absolute pointer-events-none z-10 h-full w-full p-4">
                {text.map((t, i) => {
                  return <BentoText setIndex={setIndex} setVariant={setVariant} index={i} text={t} {...props} key={i} />;
                })}
              </div>
              {/* <BentoImage image={image} {...props} /> */}
              {/* <BentoLink link={link} {...props} /> */}
              {item["__typename"] == "Thing" && <BentoThing thing={thing} {...props} />}
              {/* {item["__typename"] == "Thing" && <DisplayThing thing={thing} variant="bento" {...props} />} */}
              {item["__typename"] == "Link" && <BentoLink link={item} {...props} />}
              {item["__typename"] == "Image" && <BentoImage {...props} image={item} />}
              {item["__typename"] == "Post" && <BentoPost post={item} {...props} />}
            </div>
          </BentoBackgroundImage>
          {item["__typename"] != "Link" && <BentoInfo {...props} />}
          {/* {editMode && <BentoEdit editMode={editMode} {...components} {...props} />} */}
        </BentoParent>
        {/* <button className="absolute top-0">Hello</button> */}
      </div>
    </div>
    // </DraggableCore>
  );
};
export default BentoBox;
