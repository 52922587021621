import { useEffect, useState } from "react";

import { usePlaylistsStore } from "../stores/playlists_store";
import { getTypeFromThing } from "../utils";

export function useUpdateStage(initialProps) {
  // const [updateStageQuery, { data: updateStageData, error: updateStageError }] = useMutation(UPDATE_USER_THING_STAGE);
  // const [removeStageQuery, { data: removeStageData, error: removeStageError }] = useMutation(REMOVE_USER_THING_STAGE);
  const { thing, currentStage: initialCurrentState } = initialProps;
  const [stageToUpdate, setStageToUpdate] = useState(null);
  const [currentStage, setCurrentStage] = useState(initialCurrentState);
  const updateStagePlaylistThingStage = usePlaylistsStore((state) => state.updateStagePlaylistThingStage);

  const updateRedux = (newStage) => {
    let type = getTypeFromThing(thing);
    if (type) {
      console.log("updateStagePlaylistThingStage", type, thing, newStage);
      updateStagePlaylistThingStage({ type, thing, newStage });
    }
  };

  const handleUpdated = (newStage) => {
    console.log("UPDATED, going to initiate next state", newStage);
    setStageToUpdate(null);
    setCurrentStage(newStage);
    updateRedux(newStage);
  };

  // useEffect(() => {
  //   // console.log("updateStageData", updateStageData)
  // }, [updateStageData]);

  useEffect(() => {
    // console.log("In stage To Update!!!", stageToUpdate, thing);
    if (stageToUpdate != null) {
      try {
        // console.log("Current Stage Update", currentUser.id, thing.id, " to stage: ", stageToUpdate);
        if (stageToUpdate >= 0) {
          console.log("Updating stage", thing.id, stageToUpdate);
          fetch("/api/thing/updateStage", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              thingId: thing.id,
              newStage: parseInt(stageToUpdate),
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Update stage response:", data);
              handleUpdated(parseInt(stageToUpdate));
            })
            .catch((error) => {
              console.log("Error while updating stage", error);
              // setSuccess(false);
            });
        }
      } catch (e) {
        console.log("Error while updating stage", e);
        let error = e?.graphQLErrors[0]?.message;
        console.log(error);
        setSuccess(false);
      }
    }
  }, [stageToUpdate]);

  return [currentStage, setStageToUpdate];
}
