import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useCurrentStore = create(
  persist(
    (set) => ({
      currentThing: {},
      currentUser: {},
      setCurrentThing: (thing) => {
        set(() => ({ currentThing: thing }));
      },
      setCurrentUser: (user) => {
        set(() => ({ currentUser: user }));
      },
    }),
    {
      name: "current", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
