"use client";
import CollectionBento from "@/components/Collections/bento/collection_bento";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { Box, Edit, ImageIcon, LinkIcon, ListVideo, Heart } from "lucide-react";
import { NextResponse } from "next/server";
import { useState } from "react";
import DisplayThing from "../Thing/DisplayThing";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import Link from "next/link";
import { LogoSVG } from "../Sidebar";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Separator } from "../ui/separator";
import CollectionInteraction from "./collection_interaction";
export const handleOnDrag = (e, content) => {
  e.stopPropagation();
  console.log("HANDLEEE", e, content);
  e.dataTransfer.setData("item", JSON.stringify(content));
};

export const initiateUpdateLink = async (res, url, id) => {
  let last_updated = null;
  if (res?.links?.length > 0) {
    //Fetch the api to check the metadata info and update it
    last_updated = res.links[0];
  }
  // if (!last_updated) return; //Temporarily hide
  return fetch(`/api/update_link?id=${id}&url=${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      console.log("Success:", JSON.stringify(response));
      return NextResponse.json({ success: true });
    })
    .catch((error) => console.error("Error:", error));
};

const DisplayCollection = (props) => {
  let { variant, collection = {} } = props;
  if (variant == "appears_in") {
    return (
      <Link href={`/${collection.owner.username}/${collection.slug}`}>
        <div className="flex flex-row">
          <img src={collection?.owner?.profile_picture} className="rounded-full w-8 h-8" />
          <div>
            <div className="font-bold">{collection.name}</div>
            <div className="flex">
              <div className="text-sm">{collection?.owner?.name}</div>
              <div className="text-sm">@{collection?.owner?.username}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          {collection?.elementsConnection?.edges?.map((e, i) => {
            let { node: thing } = e;
            return <img src={thing.image} className="w-full" />;
            // return <DisplayThing thing={thing} variant="appears_in" />;
          })}
        </div>
      </Link>
    );
  } else if (variant == "home") {
    let colLength = collection?.elementsConnection?.edges?.length;
    return (
      <Link href={`/${collection.owner.username}/${collection.slug}`} className="w-[300px] min-w[300px] max-w-[300px]">
        {collection?.elementsConnection?.edges?.some((e) => e.node.__typename === "Thing") && (
          <ul className="flex flex-row overflow-hidden rounded-md h-[112px]">
            {collection?.elementsConnection?.edges?.map((e, i) => {
              let { node: thing } = e;
              if (thing.__typename != "Thing") return null;
              return (
                <li className="h-full rounded-md overflow-hidden border border-slate-600 w-[80px] min-w-[80px]" style={{ boxShadow: "2px 0 7px #000", marginRight: -25, zIndex: colLength - i }}>
                  <img src={thing.image} className="w-full object-cover h-full" />
                </li>
              );
              // return <DisplayThing thing={thing} variant="appears_in" />;
            })}
          </ul>
        )}
        <div className="">
          <div className="font-bold text-xl flex gap-2">
            {collection.name}
            <div className="flex items-center gap-1">
              <div className="text-sm text-slate-500">{collection.element_count}</div>
              <div className="flex items-center space-x-1">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="text-sm text-slate-500">
                      <LogoSVG className="w-5 h-5" />
                    </TooltipTrigger>
                    <TooltipContent>
                      {collection.element_labels?.map((l) => {
                        let label = l.label;
                        if (l.label == "Playlist") {
                          label = "collection";
                        }
                        return (
                          <div>
                            {l.totalElementCount} <span className="lowercase">{l.totalElementCount == 1 ? l.label : l.label + "s"}</span>
                          </div>
                        );
                      })}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-1">
            <div className="flex space-x-2">
              <img src={collection?.owner?.profile_picture} className="rounded-full w-5 h-5" />
              <div className="flex items-center space-x-1">
                <div className="text-sm">{collection?.owner?.name}</div>
                <div className="text-xs text-slate-500">@{collection?.owner?.username}</div>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="flex gap-3">
                <CollectionInteraction location="collection_view" initialLikeCount={collection.like_count} collection={collection} />
              </div>
            </div>
          </div>
        </div>
        {/* <Separator className="mt-2" /> */}
      </Link>
    );
  }
  return <div>Display Collection</div>;
};

export default DisplayCollection;
