"use client";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { useComposeStore } from "@/lib/stores/compose_store";
import { getTypeFromThing } from "@/lib/utils";
import { Box, PenSquare, PlusCircle, PlusSquare } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { cloneElement, useEffect, useRef, useState } from "react";
import { categoriesList } from "../../lib/categories";
import { handleOnDrag } from "../Collections/display_user_collection";
import { Button } from "../ui/button";
import { ThingTitle } from "../ui/thing";
import CommonDropdownMenuItems from "./common_menu_items";
import DisplayUserStage from "./display_user_stage";
import moment from "moment";
import { LogoSVG } from "../Sidebar";

export const ImageComponent = ({ thing, variant, className, children, width = 280, height = 130 }) => {
  const src = thing.image;
  const imgRef = useRef();
  const [colors, setColors] = useState([]);
  const bottomSectionHeight = height - width; //Because the aspect ratio would be one for the other bits
  let isFullSize = true;
  let imageAspectRatio = 1;
  if (imgRef.current) {
    const { naturalWidth, naturalHeight } = imgRef.current;
    imageAspectRatio = naturalWidth / naturalHeight;
  }
  if (imageAspectRatio == 1) {
    isFullSize = false;
  }

  const getColors = (colors) => {
    setColors(colors);
    console.log("COLORS", colors);
  };

  if (variant == "quick_add") {
    return (
      <div className={`relative mr-4 h-full`} style={{ minHeight: 130 }}>
        {/* {JSON.stringify(isFullSize)} */}

        <img ref={imgRef} src={src} aspectRatio={imageAspectRatio} style={{ width }} width={width} className={` inset-0 w-full object-cover`} alt="" />
        <div className={className}>{children}</div>
      </div>
    );
  }

  return (
    <div className={`relative overflow-hidden  h-full`} style={{ minHeight: 130 }}>
      {/* {JSON.stringify(isFullSize)} */}

      <img ref={imgRef} src={src} width={width} spectRatio={imageAspectRatio} className={` inset-0 w-full object-cover`} alt="" />
      <div
        className={`absolute flex justify-center items-center opacity-80 h-full w-[90px] `}
        style={{
          height: bottomSectionHeight,
          background: `linear-gradient(to top, transparent, ${thing.image_color || "black"}), url(${src})`,
          backgroundSize: "cover",
          backgroundColor: thing.image_color || "rgba(0, 0, 0, .3)",
          backgroundRepeat: "repeat",
          backgroundPosition: "top center",
          transform: "scale(1.05)",

          filter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur intensity
        }}
      ></div>
      <div
        style={{
          height: bottomSectionHeight,
        }}
        className={`absolute bottom-0 flex justify-center items-center h-full w-[90px] `}
      >
        {children}
      </div>
    </div>
  );
};

export const DynamicAspectImage = (props) => {
  let { width, thing = {}, no_backdrop, prioritize_backdrop = false } = props;
  let { image, backdrop } = thing;
  let type = getTypeFromThing(thing);
  let { backdrop_aspect_ratio } = categoriesList?.[type] || {};
  let aspectRatio = categoriesList[type]?.image_aspect_ratio || 1; // Fallback to 1 if type is not found
  thing.name == "The Creator" && console.log("HERE", image, prioritize_backdrop, backdrop);
  if (!no_backdrop && prioritize_backdrop && backdrop && backdrop != "TBD") {
    image = backdrop;
    aspectRatio = backdrop_aspect_ratio || 9 / 16;
  }
  if (!image) return null;
  if (image == "TBD") {
    return (
      <div
        unoptimized
        width={width}
        height={width * aspectRatio}
        style={{
          backgroundColor: "#EEEEEE",
          objectFit: "cover",
          width,
          height: "auto",
        }}
        className={` rounded-md object-cover`}
      />
    );
  }
  return <img src={image} unoptimized width={width} height={width * aspectRatio} style={{ objectFit: "cover", minWidth: width, width, height: "auto" }} className={` rounded-md object-cover`} />;
  // return <Image src={image} unoptimized width={width} height={width * aspectRatio} style={{ objectFit: "cover", width, height: width * aspectRatio }} className={`aspect-square rounded-md object-cover`} />;
};

export const ThingIcon = (props) => {
  let { thing, style } = props;
  let type = getTypeFromThing(thing);
  let {
    web_icon = () => {
      <></>;
    },
    color = "black",
  } = categoriesList?.[type] || {};
  if (!type || type == "regular") return null;
  if (!web_icon) return null;
  let iconWithProps = cloneElement(web_icon(), {
    ...props,
    color,
    className: "nav-button-icon",
  });
  return <div style={{ ...style }}>{iconWithProps}</div>;
};

export const ThingQuickAdd = (props) => {
  let { thing, openCompose } = props;
  return (
    <DropdownMenu className="w-full h-full pointer-events-auto " onClick={(e) => console.log(e)}>
      <DropdownMenuTrigger className="z-10 absolute inset-y-1/2 -mt-6 w-9 h-9 -right-5 p-2 rounded-3xl shadow-md bg-white bg-opacity-90 flex flex-row space-x-2 items-center justify-between">
        {/* <PlusSquare size={16} /> */}
        <LogoSVG className="w-7 h-7" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation(); // This stops the event from bubbling up
              openCompose(thing);
            }}
          >
            <PenSquare className="mr-2 h-4 w-4" />
            <span>Post</span>
            {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DisplayUserStage thing={thing} variant="dropdown" />
        <DropdownMenuSeparator />
        <CommonDropdownMenuItems thing={thing} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DisplayThing = (props) => {
  let { thing, index, variant, aspectRatio = 1, isRanked, order } = props;
  const { openCompose } = useComposeStore();
  let { primary_date: pd = {} } = thing;
  if (!pd) {
    pd = {};
  }
  let { dataValue: primary_date = null } = pd;
  let newDate = null;
  let new_date_format = "YYYY";
  if (primary_date) {
    newDate = moment(primary_date); // replace this with your actual date
    const current_year = moment().year();
    if (newDate.year() === current_year) {
      new_date_format = "MMMM Do"; // Full date if the year is the current year
    } else {
      new_date_format = "YYYY"; // Full date if the year is the current year
    }
  }

  const DropdownMenuData = (props) => {
    let { children } = props;
    return (
      <DropdownMenu className="w-full h-full">
        <DropdownMenuTrigger className="w-full">
          <>
            {!children && (
              <div className="flex flex-row w-full h-full justify-between pointer-events-auto">
                <div className="flex flex-col w-full h-full justify-between pointer-events-auto">
                  <p className="text-sm font-medium ">{thing.name}</p>
                  <p className="text-xs font-medium ">{thing.type_of?.[0]?.name}</p>
                </div>

                <PlusCircle size={16} />
              </div>
            )}
            {!!children && children}
          </>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <Link href={`/t/${thing?.type?.slug}/${thing.slug}`}>
                <Box className="mr-2 h-4 w-4" />
                <span>Go To Thing</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => openCompose(thing)}>
              <PenSquare className="mr-2 h-4 w-4" />
              <span>Post</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          {thing.type_of?.length > 0 && <DropdownMenuSeparator />}
          <DisplayUserStage thing={thing} variant="dropdown" />
          <DropdownMenuSeparator />
          <CommonDropdownMenuItems thing={thing} />
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  if (!thing) return null;

  if (variant == "bento_info") {
    return (
      <div>
        <DropdownMenuData />
      </div>
    );
  }

  if (variant == "home_sidebar_list") {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
        }}
        className="w-[300px] min-w-[300px] col-span-1"
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        Follow {thing.name}
        <img src={thing.image} width={60} height={60} className="object-cover" />
      </a>
    );
  }
  if (["thing"].includes(variant)) {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
          console.log("BUTTON CLICKED");
        }}
        className="relative flex-col h-full items-center justify-center rounded-md overflow-hidden gap-x-4 bg-neutral-500/5 cursor-pointer hover:bg-neutral-400/10 transition p-3"
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        <DropdownMenu className="w-full h-full pointer-events-auto" onClick={console.log}>
          <DropdownMenuTrigger>
            <div className="flex flex-row absolute right-2 top-2 z-10">
              <div className="p-2 rounded-md shadow-md bg-white bg-opacity-890 flex flex-row space-x-2">
                <ThingIcon thing={thing} size={18} />
                <PlusSquare size={16} />
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => openCompose(thing)}>
                <PenSquare className="mr-2 h-4 w-4" />
                <span>Post</span>
                {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DisplayUserStage thing={thing} variant="dropdown" />
            <DropdownMenuSeparator />
            <CommonDropdownMenuItems thing={thing} />
          </DropdownMenuContent>
        </DropdownMenu>

        {!props.hideImage && <DynamicAspectImage thing={thing} width={100} />}
        {/* {!props.hideImage && <img src={thing.image} width="100%" height="100%" className="object-cover pointer-events-none rounded-md overflow-hidden shadow-lg" />} */}
        <div className="flex flex-col justify-center items-center w-full pt-4 gap-y-1">
          <p className="font-semibold  w-full text-center">{thing.name}</p>
          {props.subtitle && <p className="text-xs font-medium ">{props.subtitle}</p>}
          <p className="text-xs font-medium ">{thing.type_of?.[0]?.name}</p>
        </div>
        {/* </div> */}
      </a>
    );
  }
  if (["thing_header_thing"].includes(variant)) {
    return (
      <div className="flex flex-col w-full ">
        <p className="font-semibold  w-full ">{thing.name}</p>
        {props.subtitle && <p className="text-xs font-medium ">{props.subtitle}</p>}
        <p className="text-xs font-medium ">{thing.type_of?.[0]?.name}</p>
      </div>
    );
  }
  if (["room"].includes(variant)) {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
          console.log("BUTTON CLICKED");
        }}
        // className="relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 bg-neutral-400/5 cursor-pointer hover:bg-neutral-400/10 transition p-3"
        className="relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 cursor-pointer hover:bg-neutral-400/10 transition"
        // className="min-w-[calc(50%-0.5rem)] h-40 bg-gray-200 rounded-xs shadow-xs"
        style={{ maxWidth: variant == "home" ? 100 : 130 }}
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        {/* <DynamicAspectImage prioritize_backdrop={props.prioritize_backdrop} thing={thing} width={variant == "home" ? 100 : 100} /> */}
        <ImageComponent thing={thing} aspectRatio={aspectRatio}>
          <ThingQuickAdd thing={thing} openCompose={openCompose} />
        </ImageComponent>
      </a>
    );
  }
  if (["room_small"].includes(variant)) {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
          console.log("BUTTON CLICKED");
        }}
        // className="relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 bg-neutral-400/5 cursor-pointer hover:bg-neutral-400/10 transition p-3"
        className="w-[40px] relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 cursor-pointer hover:bg-neutral-400/10 transition"
        // className="min-w-[calc(50%-0.5rem)] h-40 bg-gray-200 rounded-xs shadow-xs"
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        <img src={thing.image} width={50} height={50} className="object-cover aspect-square rounded-2xl overflow-hidden shadow-lg" />
        {/* <DynamicAspectImage prioritize_backdrop={props.prioritize_backdrop} thing={thing} width={variant == "home" ? 100 : 100} /> */}
        {/* <ImageComponent thing={thing} aspectRatio={aspectRatio} width={40} height={40}>
          <ThingQuickAdd thing={thing} openCompose={openCompose} />
        </ImageComponent> */}
      </a>
    );
  }
  if (["thing_with_title"].includes(variant)) {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
          console.log("BUTTON CLICKED");
        }}
        // className="relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 bg-neutral-400/5 cursor-pointer hover:bg-neutral-400/10 transition p-3"
        className="relative shrink-0 flex-col items-center justify-center rounded-md gap-x-4 cursor-pointer hover:bg-neutral-400/10 transition"
        // className="min-w-[calc(50%-0.5rem)] h-40 bg-gray-200 rounded-xs shadow-xs"
        style={{ maxWidth: variant == "home" ? 124 : 144 }}
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        <ImageComponent thing={thing} aspectRatio={aspectRatio} variant="quick_add">
          <ThingQuickAdd thing={thing} openCompose={openCompose} />
        </ImageComponent>
        {/* <ThingQuickAdd thing={thing} openCompose={openCompose} /> */}
        {/* <img src={thing.image} width="100%" height="100%" className="object-cover aspect-square pointer-events-none rounded-md overflow-hidden shadow-lg" /> */}
        {/* <DynamicAspectImage prioritize_backdrop={props.prioritize_backdrop} thing={thing} width={variant == "home" ? 100 : 100} /> */}
        {/* <div className="flex flex-col justify-center items-center w-full pt-4 gap-y-1"> */}
        <p className="w-full text-xs text-center">{thing.name}</p>
        {/* <p className="text-xs">{thing.type_of?.[0]?.name}</p> */}
        {props.subtitle && <p className="text-xs font-medium ">{props.subtitle}</p>}

        {/* </div> */}
        {/* </div> */}
      </a>
    );
  }
  if (["thing_user_header", "home", "collection_page_inner", "explore"].includes(variant)) {
    return (
      <a
        type="button"
        onClick={(e) => {
          // e.preventDefault(); // Prevent the default link behavior
          e.stopPropagation();
          console.log("BUTTON CLICKED");
        }}
        // className="relative shrink-0 flex-col items-center justify-center rounded-md overflow-hidden gap-x-4 bg-neutral-400/5 cursor-pointer hover:bg-neutral-400/10 transition p-3"
        className="relative shrink-0 flex-col items-center justify-center rounded-md gap-x-4 cursor-pointer hover:bg-neutral-400/10 transition"
        // className="min-w-[calc(50%-0.5rem)] h-40 bg-gray-200 rounded-xs shadow-xs"
        style={{
          maxWidth: variant == "home" ? 124 : null,
        }}
        draggable
        onDragStart={(e) => handleOnDrag(e, thing)}
        href={`/t/${thing?.type?.slug}/${thing?.slug}`}
        key={index}
      >
        <ImageComponent thing={thing} aspectRatio={aspectRatio} variant="quick_add" width={props.width}>
          {!!props.includeName && <p className="text-sm text-center">{thing.name}</p>}
          <ThingQuickAdd thing={thing} openCompose={openCompose} />
        </ImageComponent>

        {/* <ThingQuickAdd thing={thing} openCompose={openCompose} /> */}
        {/* <img src={thing.image} width="100%" height="100%" className="object-cover aspect-square pointer-events-none rounded-md overflow-hidden shadow-lg" /> */}
        {/* <DynamicAspectImage prioritize_backdrop={props.prioritize_backdrop} thing={thing} width={variant == "home" ? 100 : 100} /> */}
        {/* <div className="flex flex-col justify-center items-center w-full pt-4 gap-y-1"> */}
        {/* <p className="w-full text-xs text-center">{thing.name}</p>
          <p className="text-xs">{thing.type_of?.[0]?.name}</p> */}
        {/* </div> */}
        {/* </div> */}
      </a>
    );
  }
  let type = getTypeFromThing(thing);

  return (
    <Link
      className="flex"
      draggable
      onDragStart={(e) => {
        e.stopPropagation();
        handleOnDrag(e, thing);
      }}
      href={`/t/${thing?.type?.slug}/${thing?.slug}`}
      key={index}
      style={{}}
    >
      {variant == "collection" && <DynamicAspectImage unoptimized thing={thing} width={64} height={64} className={`rounded-md object-cover`} />}
      {variant == "bento" && (
        <div className="relative flex flex-col justify-center items-center h-full z-8">
          <img
            src={thing.image}
            className={thing.className}
            style={{
              pointerEvents: "none",
              objectFit: "cover",
              aspectRatio: 9 / 16,
            }}
          />
        </div>
      )}
      {variant == "post" && (
        <div className="flex flex-row items-center space-x-2" style={{ whiteSpace: "initial" }}>
          <Image unoptimized className="w-8 h-8 rounded-full" width={11} height={11} src={thing.image} alt="Profile picture" style={{ pointerEvents: "none" }} />
          <div className="flex flex-col">
            <ThingTitle>{thing.name}</ThingTitle>
            <div className="text-sm text-primary-subtitle">{categoriesList[type]?.name}</div>
          </div>
        </div>
      )}
      {variant == "collection_page_compact" && (
        <div className="flex items-center p-2 pl-4 pr-4 w-full gap-4 border-b">
          {isRanked && <div>{order}</div>}
          <div className="">
            {/* <ImageComponent thing={thing} width={60} no_backdrop /> */}
            <ImageComponent thing={thing} aspectRatio={aspectRatio}>
              <ThingQuickAdd thing={thing} openCompose={openCompose} />
            </ImageComponent>
          </div>
          {/* <div className="flex flex-row w-full justify-between gap-4">
            <div>
              <h2 className="text-lg font-semibold">{thing.name}</h2>
              <h2 className="text-xs font-semibold">{thing.type?.name}</h2>
            </div>

            <div className="flex space-x-2">
              <Button className="w-auto" variant="outline">
                Follow
              </Button>
              <Button className="w-auto" variant="outline">
                Add to Collection
              </Button>
            </div>
          </div> */}
        </div>
      )}
      {variant == "collection_page" && (
        <div className="flex items-start gap-4 relative p-4 bg-zinc-100 rounded-lg">
          {isRanked && <div>{order}</div>}

          <div className="min-w-[168px]">
            {/* <DynamicAspectImage thing={thing} width={168} /> */}
            <DisplayThing thing={thing} variant="collection_page_inner" width={190} />
          </div>
          <div className="grow space-y-2">
            <div>
              <h2 className="text-lg font-semibold">{thing.name}</h2>
              <h2 className="text-xs font-semibold">{thing.type?.name}</h2>
            </div>
            <p className="text-xs text-zinc-500 dark:text-zinc-400">{thing.description}</p>
            {/* <p className="text-xs text-zinc-500 dark:text-zinc-400">Released: March 31, 1999</p> */}
            <p className="text-xs text-zinc-500 dark:text-zinc-400">{moment(primary_date).format(new_date_format)}</p>
            <div className="flex space-x-2">
              <Button className="w-auto" variant="outline">
                Follow
              </Button>
              <Button className="w-auto" variant="outline">
                Add to Collection
              </Button>
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};

export default DisplayThing;
