"use client";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useEffect, useRef, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import Search from "../Search/search";
import { Label } from "@/components/ui/label";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { v4 as uuidv4 } from "uuid";
import { useComposeStore } from "@/lib/stores/compose_store";
import { Star, Image as ImageIcon, Edit } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Badge } from "../ui/badge";
import { getTypeFromThing } from "@/lib/utils";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
// import { PostCard } from "../ui/card_post";
import { useRouter } from "next/navigation";
import { useSearchStore } from "@/lib/stores/search_store";
import { useCurrentStore } from "@/lib/stores/current_store";
import { darkenHexColor, extractInitials } from "@/lib/helperFunctions";
import { useStylesStore } from "@/lib/stores/styles_store";

export const ComposeButton = (props) => {
  let { children, className } = props;
  const { isComposeOpen, selectedItem, closeCompose, openCompose, toggleCompose } = useComposeStore();
  const [isHovered, setIsHovered] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const [collectionHovered, setCollectionHovered] = useState(null);
  const globalStyles = useStylesStore((state) => state.globalStyles);

  const handleDragOver = (e, i) => {
    e.preventDefault();
    setIsHovered(true);
    try {
      let draggedData = e.dataTransfer.types;
      if (draggedData.length < 3) {
        setHoveredType(null);
        return;
      } else {
        setHoveredType(draggedData[draggedData.length - 1]);
      }
    } catch (error) {
      console.log("ERROR", error);
      setHoveredType(true);
    }
  };
  const handleOnDrop = async (e) => {
    let itemDropped, arbitraryDrop;
    e.preventDefault();
    console.log("itemDropped", itemDropped);
    const itemData = e.dataTransfer.getData("item");
    try {
      itemDropped = JSON.parse(itemData);
    } catch (error) {
      console.error("Error parsing 'item' data:", error);
    }
    console.log("ITEMDROPPED", itemDropped);
    let type = itemDropped?.["__typename"] || "";
    console.log("ITEMDROPPED<", type);
    if (itemData || type == "Link" || type == "Image" || type == "Thing") {
      console.log("TYPE", type);
      setIsHovered(false);
      setHoveredType(type);
      let data = { type };
      const thingId = itemDropped.id;
      data["assetId"] = thingId;
      data = { ...data, ...itemDropped };
      console.log("DDD", data);
      console.log("openCompose", openCompose);
      openCompose(data);
      console.log("SHOULD HAVE OPENED");
    }
  };

  const handleDragLeave = (e, i) => {
    e.preventDefault();
    setIsHovered(false);
    setHoveredType(null);
  };

  console.log("ISCOMPOSEOPEN", isComposeOpen);
  return (
    <div
      // onClick={() => openCompose()}
      onDrop={(e) => handleOnDrop(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDragOver={(e) => handleDragOver(e)}
      className={`box-border  border-dashed  ${isHovered ? "border-4" : "border-transparent"} 
    ${hoveredType == "thing" ? "border-cyan-500" : hoveredType == "image" ? "border-green-500" : hoveredType == null && isHovered ? "border-yellow-500" : "transparent"}`}
    >
      <Dialog open={isComposeOpen} onOpenChange={toggleCompose} onDragOver={(e) => handleDragOver(e)} className="pointer-events-auto">
        <DialogTrigger
          className={`${className}`}
          style={{ backgroundColor: collectionHovered ? darkenHexColor(globalStyles.sidebar_bg, 8) : null }}
          onMouseEnter={() => setCollectionHovered(true)}
          onMouseLeave={() => setCollectionHovered(false)}
        >
          {/* Compose */}
          {children}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>{/* <DialogTitle><Edit /> Compose</DialogTitle> */}</DialogHeader>
          <Compose />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const uploadImages = async (images, bucket) => {
  const supabase = createClientComponentClient();

  // Assuming images is an array of File objects
  let uploadedImages = [];
  for (let i = 0; i < images.length; i++) {
    if (!images[i]) continue;
    const image = images[i];
    const fileName = uuidv4();
    const { data, error } = await supabase.storage.from(bucket).upload(fileName, image);
    if (error) {
      console.error("Error uploading image:", error);
      return;
    }
    const { data: publicData } = supabase.storage.from(bucket).getPublicUrl(fileName);
    uploadedImages.push(publicData.publicUrl);
  }
  return uploadedImages;
};

const Compose = (props) => {
  let { isInlineReply = false, isReply = false, post: initialPost = {}, onPostSuccess, room } = props;
  if (isInlineReply) {
    isReply = true;
  }
  const fileInputRef = useRef(null);

  const [body, setBody] = useState("");
  let initialThings = [];
  if (initialPost?.thingsConnection?.edges?.length > 0) {
    let { thingsConnection = { edges: [] } } = initialPost;
    initialThings = thingsConnection.edges?.map((e) => {
      return { ...e.node, logDate: e.logDate };
    });
  }

  const { things = [], addThing, removeThing } = useComposeStore();

  const [rating, setRating] = useState(null);

  // const [things, setThings] = useState(initialThings || []);
  const [addImage, setAddImage] = useState(false);
  const [addRating, setAddRating] = useState(false);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isRepost, setIsRepost] = useState(false);
  const [postType, setPostType] = useState("text");
  const { toast } = useToast();
  const supabase = createClientComponentClient();
  const router = useRouter();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const setSearchInput = useSearchStore((state) => state.setSearchInput);
  const searchInput = useSearchStore((state) => state.searchInput);
  const searchResults = useSearchStore((state) => state.searchResults);
  const currentUser = useCurrentStore((state) => state.currentUser);
  useEffect(() => {
    if (searchInput?.["compose_inline"] && searchResults && searchResults != filteredSuggestions) {
      setShowSuggestions(true);
      setFilteredSuggestions(searchResults);
    }
  }, [searchResults]);

  const updateBody = (text) => {
    setBody(text);

    let atIndex = text.lastIndexOf("@");
    if (atIndex !== -1) {
      const searchTerm = text.substring(atIndex + 1);
      let spaceCount = (searchTerm.match(/\s/g) || []).length;
      console.log("SOACECOUNT", spaceCount);
      let spaceIndex = searchTerm.indexOf(" ");
      if (spaceCount <= 1) {
        if (spaceIndex !== -1) {
          let wordAfterLastSpace = searchTerm.substring(spaceIndex + 1);
          if (wordAfterLastSpace.length <= 5) {
            setSearchInput({ ...searchInput, ["compose_inline"]: searchTerm });
            setShowSuggestions(true);
          } else {
            setShowSuggestions(false);
          }
        } else {
          // No space after '@', consider the entire substring
          setSearchInput({ ...searchInput, ["compose_inline"]: searchTerm });
          setShowSuggestions(true);
        }
      } else {
        setSearchInput(false);
        setShowSuggestions(false);
      }
    } else {
      setSearchInput(false);
      setShowSuggestions(false);
    }
    // const words = text.split(/\s+/);
    // const lastWord = words[words.length - 1];

    // if (lastWord.startsWith('@')) {
    //   const searchTerm = lastWord.substring(1);
    //   setSearchInput({ ...searchInput, ["compose_inline"]: searchTerm });

    // } else {
    //   setShowSuggestions(false);
    // }
  };

  const submitPost = async () => {
    console.log("AM HERE1");
    if (!body) {
      setErrors({ body: "Need a body? Not sure yet" });
      toast({
        title: `Post body needed!`,
        color: "red",
      });
      return;
    }
    if (addRating && rating && rating >= 0 && things.length != 1) {
      setErrors({ rating: "You can only rate Things (one at a time)" });
      console.log("You can only rate Things (one at a time)");
      toast({
        title: `You can only rate things one at a time!`,
        color: "red",
      });
      return;
    }
    let date = new Date();

    const userId = currentUser?.id;

    try {
      // console.log("IMG LENGTH", images.length);
      let uploadedImages = null;
      if (images.length > 0) {
        uploadedImages = await uploadImages(images, "posts");
      }

      let variables = {
        userId,
        body,
        date: date.toISOString(),
        things,
        images: uploadedImages,
        isReply,
        room,
        originalPostId: isReply ? props.post.id : null,
        rating: addRating ? rating : null,
      };
      console.log("Varaz", variables);

      fetch("/api/post/compose", {
        method: "POST",
        body: JSON.stringify(variables),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("Success:", JSON.stringify(response));
          onPostSuccess(response.createPosts.posts[0]);
          toast({
            title: `Post submitted!`,
          });
          // const postId = response.createPosts.posts[0].id;
          // router.push(`/post/${postId}`);
        })
        .catch((error) => console.error("Error:", error));
    } catch (e) {
      console.log("errrrror", JSON.stringify(e, null, 2));
      console.log("errrrror", e);
      toast({
        title: `Error, ${JSON.stringify(e, null, 2)}`,
      });
    }
  };

  const onResultClick = (thing) => {
    setSearchInput({ ...searchInput, ["compose_inline"]: "" });
    setShowSuggestions(false);
    let thingIds = things.map((t) => t.id);
    if (!thingIds.includes(thing.id)) {
      // setThings([...things, thing]);
      if (things.length < 5) {
        addThing(thing);
      } else {
        toast({
          title: `Can only add 5 things at a time`,
        });
      }
    } else {
      toast({
        title: `[X] Thing already added`,
      });
    }
    let text = body;
    let wordClicked = thing.name;
    let atIndex = text.lastIndexOf("@");

    if (atIndex !== -1) {
      let beforeAt = text.substring(0, atIndex);
      let afterAt = "@" + wordClicked + " ";
      setBody(beforeAt + afterAt);
      // let wordClicked = thing.name
      // const words = text.split(/\s+/);
      // const lastWord = words[words.length - 1];

      // if (lastWord.startsWith('@')) {
      //   words[words.length - 1] = '@' + wordClicked;
      //   const newText = words.join(' ');
      //   setBody(newText);
      // }
    }
  };

  const handleImage = (event) => {
    const avatarFile = event.target.files[0];
    setImages([...images, avatarFile]);
    fileInputRef.current.value = "";
  };

  // Function to handle rating change
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Star Rating Component
  const StarRating = () => {
    return (
      <div>
        {[1, 2, 3, 4, 5].map((star) => (
          <button key={star} className={`star ${rating >= star ? "filled" : ""}`} onClick={() => handleRatingChange(star)}>
            <Star fill={rating >= star ? "black" : "transparent"} size={20} />
          </button>
        ))}
      </div>
    );
  };

  if (isInlineReply) {
    return (
      <Card key="1" className="w-full pl-0 pr-0 space-y-4 border-0 shadow-none">
        <CardHeader>
          <div className="flex items-center gap-3">
            <Avatar className="h-9 w-9">
              <AvatarImage alt="User Avatar" src={currentUser.profile_picture} />
              {currentUser.name && <AvatarFallback>{extractInitials(currentUser.name)}</AvatarFallback>}
            </Avatar>
            <CardContent className="w-full">
              <div className="flex flex-col space-y-1.5 w-full">
                {/* <Label htmlFor="post">What's happening?</Label> */}
                <Textarea id="post" placeholder={`Reply to @${props.post.author?.username}`} value={body} autoCorrect="false" multiline="true" onChange={(e) => updateBody(e.target.value)} />
                {/* <Button variant="outline">GIF</Button> */}
                <div />
              </div>
              <Button variant="secondary" onClick={submitPost}>
                Submit
              </Button>
            </CardContent>
          </div>
        </CardHeader>

        {/* <CardFooter className="flex justify-end">
    </CardFooter> */}
      </Card>
    );
  }

  return (
    <Card key="1" className="pl-0 pr-0 space-y-4 border-0 shadow-none">
      <CardHeader>
        <div className="flex items-center gap-3">
          <Avatar className="h-9 w-9">
            <AvatarImage alt="User Avatar" src={currentUser.profile_picture} />
            {currentUser.name && <AvatarFallback>{extractInitials(currentUser.name)}</AvatarFallback>}
          </Avatar>
          <div className="font-medium">{currentUser.name}</div>
        </div>
      </CardHeader>
      <CardContent>
        <div>
          <div className="grid w-full gap-1">
            <div className="flex flex-col space-y-1.5">
              {/* <Label htmlFor="post">What's happening?</Label> */}
              <Textarea
                id="post"
                placeholder={isRepost ? "Share your thoughts..." : postType != "question" ? "The world is your oyster" : "What is your question?"}
                value={body}
                autoCorrect="false"
                multiline="true"
                onChange={(e) => updateBody(e.target.value)}
              />
              {/* {JSON.stringify(searchInput)} */}
              {/* {JSON.stringify(searchResults)} */}
              {/* <Search onResultClick={onResultClick} showSuggestions={showSuggestions} location="compose_inline" variant="compose_inline" /> */}
              {/* {showSuggestions && (
                <ul className="border p-2 bg-white w-full">
                  {filteredSuggestions.map((suggestion, index) => (
                    <Badge>
                      <Avatar className="h-5 w-5">
                        <AvatarImage alt="User 1" src="/placeholder-avatar.jpg" />
                        <AvatarFallback>U1</AvatarFallback>
                      </Avatar>
                      <span className="ml-2">{suggestion.suggestionNum}</span>
                    </Badge>            ))}
                </ul>
              )} */}
              {things.map((t, i) => {
                let type = getTypeFromThing(t);
                return (
                  <div key={i} className="flex flex-row w-full justify-between items-start hover:bg-cyan-100" onClick={() => removeThing(i)}>
                    <div>
                      <div className="text-md">{t.name}</div>
                    </div>
                    <div>
                      <Badge className="text-sm capitalize" variant="secondary">
                        {type}
                      </Badge>
                    </div>
                  </div>
                );
              })}
              {/* {JSON.stringify(things)} */}
              {addRating && (
                <div className="flex items-center gap-0.5">
                  <StarRating />
                </div>
              )}
              {images.map((i) => (
                <img src={URL.createObjectURL(i)} style={{ width: 200, height: 100 }} />
              ))}
            </div>
            <div className="flex justify-between">
              {/* <Button variant="outline"> */}
              {/* <Label htmlFor="picture">Add Things</Label> */}
              <div className="flex">
                <Search onResultClick={onResultClick} variant="compose" />
                {/* </Button  > */}
                <Button variant="ghost" size="icon" onClick={() => handleImageClick(!addImage)}>
                  <ImageIcon className="w-5 h-5" />
                  <Input ref={fileInputRef} id="image" type="file" className="hidden" onChange={handleImage} />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    setAddRating(!addRating);
                    if (addRating) {
                      handleRatingChange(null);
                    }
                  }}
                >
                  <Star />
                </Button>
              </div>
              {/* <Button variant="outline">GIF</Button> */}
              <div />
              <Button variant="secondary" onClick={submitPost}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {/* <CardFooter className="flex justify-end">
      </CardFooter> */}
    </Card>
  );
};

export default Compose;
