import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { categoriesList } from "../categories";
import { getTypeFromThing } from "../utils";

export const useRoomStore = create(
  persist(
    (set, get) => ({
      userRooms: [],
      resetRooms: () => {
        return set({
          userRooms: [],
        });
      },
      setUserRooms: (newRooms) => {
        console.log("IN USER ROOM", newRooms);
        return set((state) => {
          const updatedRooms = new Map(state.userRooms.map((room) => [room.id, room]));
          newRooms.forEach((room) => updatedRooms.set(room.id, room));
          return {
            ...state,
            userRooms: Array.from(updatedRooms.values()),
          };
        });
      },
      getUserRooms: () => get().userRooms,
    }),
    {
      name: "rooms", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
