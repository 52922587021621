"use client";
import { PenSquare, Check, PlusCircle, User, CreditCard, Mail, UserPlus, MessageSquare } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { usePlaylistsStore } from "@/lib/stores/playlists_store";
import { useComposeStore } from "@/lib/stores/compose_store";
import CommonDropdownMenuItems from "./common_menu_items";
import { ExternalLink, Copy } from "lucide-react";
const DisplayLink = (props) => {
  let { link, index, variant, aspectRatio = 1 } = props;
  const userPlaylists = usePlaylistsStore((state) => state.userPlaylists);
  const addLinkToPlaylist = usePlaylistsStore((state) => state.addLinkToPlaylist);
  const { openCompose } = useComposeStore();
  if (!link) return null;

  if (variant == "bento_info") {
    return (
      <DropdownMenu className="w-full h-full pointer-events-auto">
        <DropdownMenuTrigger className="flex flex-row w-full h-full justify-between pointer-events-auto">
          <p className="text-sm font-medium leading-none">{link.metadata?.ogTitle || link.metadata?.title}</p>
          <p className="text-sm font-medium leading-none">Link</p>
          <PlusCircle size={16} />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => openCompose(link)}>
              <PenSquare className="mr-2 h-4 w-4" />
              <span>Post</span>
              {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => openCompose(link)}>
              <ExternalLink className="mr-2 h-4 w-4" />
              <span>Go to Link</span>
              {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => openCompose(link)}>
              <Copy className="mr-2 h-4 w-4" />
              <span>Copy Link</span>
              {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
            </DropdownMenuItem>
          </DropdownMenuGroup>
          {/* <DropdownMenuSeparator /> */}
          {/* <DisplayUserStage thing={thing} variant="dropdown" /> */}
          <DropdownMenuSeparator />
          <CommonDropdownMenuItems link={link} />
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
};

export default DisplayLink;
