import { create } from "zustand";

export const useSearchStore = create((set) => ({
  searchInput: {},
  searchResults: {},
  setSearchInput: (text) => {
    set(() => ({ searchInput: text }));
  },
  setSearchResults: (results) => {
    set(() => ({ searchResults: results }));
  },
}));
