"use client";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { usePlaylistsStore } from "../stores/playlists_store";

export const signOutUser = async () => {
  const supabase = createClientComponentClient();
  fetch("/auth/signout", {
    method: "POST",
  })
    .then((res) => res.json())
    .then(async (response) => {
      console.log("IN SIGN OUT", response);
      const { error } = await supabase.auth.signOut();
      usePlaylistsStore.resetPlaylists();
      console.log("ERRRORRR", error);
      redirect("/");
    })
    .catch((error) => console.error("Error signing out user:", error));
};
