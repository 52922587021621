import { uploadImages } from '@/components/Compose/compose';
import { useToast } from '@/components/ui/use-toast';
import { useCallback, useState } from 'react';

const DropZone = ({ children, collection, disabled }) => {
    const [droppedFiles, setDroppedFiles] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    let toast = useToast()
    const handleImageDropped = async (imageFiles) => {

        uploadImages(imageFiles, 'boards').then((images) => {
            console.log("IMAGESZ", images)
            let image = images?.[0]
            // toast({
            //     title: `Nothing to upload`,
            // });
            console.log("IMAGE", image)
            if (!image) return;
            let data = { collectionId: collection.id, url: image };

            fetch("/api/library/collections/add_image", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then(async (response) => {
                    console.log("Success:", JSON.stringify(response));
                    // toast({
                    //     title: `Saved ${url} to ${collection.name}`,
                    // });
                })
                .catch((error) => console.error("Error:", error));
        }).catch((error) => {
            console.log("ERROR", error)
        })

    }



    const onDrop = useCallback((event) => {
        if (disabled) return

        setIsHovered(false);
        event.preventDefault();
        const files = [...event.dataTransfer.files];
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length > 0) handleImageDropped(imageFiles)
        const links = files.filter(file => file.type === 'text/uri-list');

        // Process images or links here
        setDroppedFiles([...droppedFiles, ...imageFiles, ...links]);
        console.log([...droppedFiles, ...imageFiles, ...links])
    }, [droppedFiles]);

    const onDragOver = (event) => {
        if (disabled) return
        event.preventDefault();
        setIsHovered(true)
    };

    return (
        <div
            className={`w-full h-screen ${isHovered ? 'bg-gray-100 border border-8 border-dashed' : ''}`}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={() => setIsHovered(false)}
        >
            {children}
            {/* <p>Drag and drop files here</p>
            {droppedFiles.map((file, index) => (
                <div key={index}>
                    {file.type.startsWith('image/') && <img src={URL.createObjectURL(file)} alt={`Dropped Image ${index}`} />}
                    {file.type === 'text/uri-list' && <a href={URL.createObjectURL(file)}>Dropped Link</a>}
                </div>
            ))} */}
        </div>
    );
};

export default DropZone;
